import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import request from '../../../utils/request';

import history from "../../../utils/history";
import styles from "./styles.module.scss";
import TitleBar from "../../../components/front/TitleBar";

const COLORS = [
    '#6b3249',
    '#a0abde',
    '#367bac',
    '#a22750',
    '#35057e',
    '#eb9c3f',
    '#a22298',
    '#f6c951',
    '#a22750',
    '#35057e',
    '#eb9c3f',
    '#a22298',
    // "#732E4A",
    // "#FFC72C",
    // "#007DB1",
    // "#3B0063",
    // "#9DABE2",
    // "#E17000",
    // "#B1059D",
    // "#00B388",
    // "#ef203c",
    // "#b1124f",
    // "#f8971d",
    // "#5f5f61",
];

const Shape = () => (
    <div className={styles.shape}>
        <img src="/shape.png" alt="" />
    </div>
);

const SwitchView = ({ products, category }) => {
    const [ sections, setSections ] = useState([]);
    const [ section, setSection ] = useState();

    useEffect(() => {
        request('sections').then(({ data }) => {
            setSections(data.data);
        });
    }, []);

    return (
        <>
            <TitleBar isSwitch colorIndex={7} />
            <div className={styles.container} style={{ backgroundImage: 'url(/intro-bg.jpg)' }}>
                <div className={styles.grid}>
                    <div className={styles.col}>
                        <div className={styles.intro}>
                            <div>
                                <h1>Welcome to Amryt Academy</h1>
                                <p>Explore our learning resources by clicking the options:</p>
                            </div>
                            <div>
                                <img src="/logo-white.svg" alt="" />
                                <p>The content of the Amryt Academy is intended for internal educational purposes and the content must not be used externally without MRC approval for medical materials and PRC approval for promotional materials.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div>
                            {section ? products.filter((o) => o.section_id === section.id).map((p, index) => (
                                <div
                                    key={`product-${p.id}`}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={(e) => {
                                        localStorage.setItem("pid", p.id);
                                        window.location.href = `/${p.slug}`;
                                        return e.preventDefault();
                                    }}
                                >
                                    {p.logo ? <img src={p.logo} alt="" /> : <p>
                                        {p.name}
                                        <small>
                                            {p.name.replace('Birch ', 'Birch-').replace('& ', '&-').split(" ").length > 1 ? <span>{p.name.replace('Birch ', 'Birch-').replace('& ', '&-').split(" ")[1].replace('Birch-', 'Birch ').replace('&-', '& ')}</span> : false}
                                            {p.slug === "lojuxta-lomitapide" || p.slug === "myalepta-metreleptin" ? <div className={styles.special}>▼</div> : false}
                                        </small>
                                    </p>}
                                    <h3>Learn now</h3>
                                </div>
                            )) : sections.map((o) => (
                                <div key={`section-${o.id}`}>
                                    <div
                                        className={`${styles.product} ${styles.section}`}
                                        onClick={e => {
                                            setSection(o);
                                            return e.preventDefault();
                                        }}
                                    >
                                        {o.logo ? <img src={o.logo} alt="" /> : <p>{o.name}</p>}
                                        <h3>Learn now</h3>
                                    </div>
                                </div>
                            ))}
                            {section && (
                                <div>
                                    <div
                                        className={`${styles.product} ${styles.section}`}
                                        onClick={e => {
                                            setSection(undefined);
                                            return e.preventDefault();
                                        }}
                                    >
                                        <p>Back to sections</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerBackground}>
                    <div className={styles.black} />
                </div>
                <div className={styles.grid}>
                    <div className={styles.col}>
                        <div className={styles.left}>
                            <p>If you receive a report of an adverse event, please report this, in compliance with the Amryt SOP-00329, Safety Reporting for Marketed Products.</p>
                            <div className={styles.options}>
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/email.png" alt="" style={{ height: 44 }} />
                                    </div>
                                    <p>
                                        These can be reported using the medical information email<br />
                                        <a href="mailto:medinfo@amrytpharma.com">medinfo@amrytpharma.com</a>
                                    </p>
                                </div>
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/phone.png" alt="" />
                                    </div>
                                    <p>
                                        or by phone using<br />
                                        <b>+800 4447 4447</b> (toll free)<br />
                                        or <b>+44 1604 549952</b> (toll paid)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.right}>
                            <p>The content on the Amryt Academy is intended for internal educational purposes and the content must not be used externally without MRC approval for medical materials and PRC approval for promotional materials.</p>
                            <ul>
                                <li>All activities must be organized and conducted in compliance with applicable laws, regulations, codes, Amryt Code of Conduct, Amryt Ethical Principles and Amryt procedural documents.</li>
                                <li>At planning phase and prior to conducting an activity in a country whether it is globally or country initiated, it must be ensured that it fully adheres to and satisfies all applicable country regulations, requirements and Amryt procedural documents.</li>
                                <li>Promotion is only allowed following Marketing Authorization or price and reimbursement depending on the countries regulations.</li>
                                <li>All materials used in activities and interactions must be reviewed and approved in accordance with applicable procedural documents prior to their use.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.container}>
                <h1>Welcome to Amryt-academy.com</h1>
                <h2>
                    Explore our learning resources: click the boxes to select
                    your product
                </h2>
                <div className={styles.row}>
                    <Shape />
                    <div
                        className={styles.products}
                        style={section && products.filter((o) => o.section_id === section.id) > 9 ?
                            { gridTemplateColumns: 'repeat(4, 200px)' }
                            : (section ? { gridTemplateRows: `repeat(${products.filter((o) => o.section_id === section.id).length > 3 ? 2 : 1}, 1fr)` } : { gap: 16 })
                        }
                    >
                        {section ? products.filter((o) => o.section_id === section.id).map((p, index) => (
                            p.logo ? (
                                <div
                                    style={{ backgroundColor: COLORS[index] }}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={(e) => {
                                        localStorage.setItem("pid", p.id);
                                        window.location.href = `/${p.slug}`;
                                        return e.preventDefault();
                                    }}
                                >
                                    <img src={p.logo} alt="" />
                                </div>
                            ) : (
                                <Product
                                    key={`Product__${p.id}`}
                                    color={COLORS[index]}
                                    {...p}
                                />
                            )
                        )) : sections.map((o) => (
                            <div key={`section-${o.id}`}>
                                <div
                                    style={{ backgroundColor: '#0d0f24' }}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={e => {
                                        setSection(o);
                                        return e.preventDefault();
                                    }}
                                >
                                    {o.logo ? <img src={o.logo} alt="" /> : o.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <p>
                    The content of the Amryt Academy is intended for internal
                    educational purposes and the content must not be used <br />
                    externally without MRC approval for medical materials and
                    PRC approval for promotional materials.
                </p>
            </div> */}
        </>
    );
};

const Product = ({ disclaimer, name, id, slug, color }) => {
    let names = name.replace('Birch ', 'Birch-').replace('& ', '&-').split(" ");
    let isSpecial = slug === "lojuxta-lomitapide" || slug === "myalepta-metreleptin";
    return (
        <Link
            style={{ backgroundColor: color }}
            className={styles.product}
            to={`/${slug}`}
            onClick={e => {
                localStorage.setItem("pid", id);
                window.location.href = `/${slug}`;
                return e.preventDefault();
            }}
        >
            <div className={styles.logo}>
                <h3>
                    {names[0]} <small>&reg;</small>
                </h3>
                {names.length > 1 ? <span>{names[1].replace('Birch-', 'Birch ').replace('&-', '& ')}</span> : false}
                {isSpecial ? <div className={styles.special}>▼</div> : false}
            </div>

            <div className={styles.angle}>
                <Icon name="chevron right" />
            </div>

            <p>
                {disclaimer}
                {/* This site is intended for employees in{" "}
                <span className="region">{region}</span> */}
            </p>
        </Link>
    );
};

export default connect(state => ({
    products: state.product.products
}))(SwitchView);
