import { combineReducers, createStore } from 'redux';
import category from './reducers/category';
import loader from './reducers/loader';
import product from './reducers/product';
import user from './reducers/user';

export default createStore(combineReducers({
    category,
    loader,
    product,
    user,
}));
