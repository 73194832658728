import React from 'react';
import { connect } from 'react-redux';
import { each, find } from 'lodash';
import ProductLink from '../ProductLink';
import styles from './styles.module.scss';

class Breadcrumb extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            product: false
        };
    }

    componentDidMount = () => {
        const { products } = this.props;
        const product = find(products, {
            id: parseInt(localStorage.getItem("pid"))
        });
        this.setState({ product });
    };

    // --- render
    render() {
        const { crumb } = this.props;
        const { product } = this.state;
        let i = 0;
        const links = [];
        const length = Object.keys(crumb).length;

        if (product) {
            links.push((
                <ProductLink
                    className={styles.link}
                    key="prod-home"
                    to="/"
                >
                    {product.name.toUpperCase()}
                </ProductLink>
            ));
        }

        each(crumb, (label, url) => {
            links.push((
                <ProductLink
                    className={[
                        styles.link,
                        length !== 1 && (i++) >=  length - 1 ? styles.isActive : null
                    ].join(' ')}
                    key={url}
                    to={`/category/${url}`}
                >
                    {label.replace('<br />', ' ')}
                </ProductLink>
            ));
        });

        return (
            <div className="cont">
                <div className={styles.container}>
                    {links}
                    {length === 1 ? (
                        <ProductLink
                            className={[
                                styles.link,
                                styles.isActive
                            ].join(' ')}
                            to="/"
                        >
                            all
                        </ProductLink>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    products: state.product.products
}))(Breadcrumb);

