import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Segment } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import history from '../../../../utils/history';
import request from '../../../../utils/request';

const { Button, Group, Input } = Form;

class AdminSectionsListView extends React.Component
{
    state = {
        errors: {},
        section: {},
        logo: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/sections/${this.props.match.params.id}`).then(({ data }) => {
            this.setState({
                section: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, section } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            section: {
                ...section,
                [name]: value,
            },
        });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        
        const fd = new FormData();
        fd.append('name', this.state.section.name);
        if (this.state.logo) {
            fd.append('logo', this.state.logo);
        }
        
        request.post(`admin/sections${id ? `/${id}` : ''}`, fd).then(() => {
            toast({
                type: 'success',
                icon: '',
                description: 'Section saved succesfully',
                time: 2500,
            });
            history.push('/admin/sections');
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
            });
        });
    }

    // --- render
    render() {
        const { errors, section } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">{section.id ? 'Edit' : 'Create'} section</Header>
                        <Link to="/admin/sections">Back</Link>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Input
                            error={errors.name && errors.name !== false}
                            label="Name"
                            name="name"
                            onChange={this.handleInput}
                            required
                            value={section.name || ''}
                        />
                        <Input
                            label="Logo"
                            name="logo"
                            onChange={(e, data) => this.setState({ logo: e.target.files[0]})}
                            type="file"
                        />
                        <Group>
                            <Button primary>Save</Button>
                            <Link to="/admin/sections" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                        </Group>
                    </Form>
                </Segment>
            </Container>
        );
    }
}

export default AdminSectionsListView;
