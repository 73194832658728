import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Input, Segment, Table } from 'semantic-ui-react'
import { toast } from 'react-semantic-toasts';
import request from '../../../../utils/request';
import tableStyles from '../../../../styles/table.module.scss';
import DeleteAction from '../../../../components/admin/DeleteAction';
import Pagination from '../../../../components/admin/Pagination';

const { Cell, HeaderCell, Row} = Table;

let timer = 0;

class AdminFaqsListView extends React.Component
{
    state = {
        isLoading: false,
        query: '',
        pagination: {},
        faqs: [],
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Delete category
     */
    deleteFaq = (faq) => {
        request.delete(`admin/faqs/${faq.id}`).then(() => {
            this.fetch();

            toast({
                type: 'success',
                icon: '',
                description: 'FAQ deleted succesfully',
                time: 2500,
            });
        });
    }

    /**
     * Fetch
     */
    fetch = (page) => {
        request(`admin/faqs?q=${this.state.query}&with_categories=1&page=${page || 1}`).then(({ data }) => {
            this.setState({
                isLoading: false,
                pagination: data.meta,
                faqs: data.data,
            });
        });
    }

    /**
     * Search
     */
    searchFaqs = (query) => {
        clearTimeout(timer);

        this.setState({
            isLoading: true,
            query,
        });

        timer = setTimeout(() => { this.fetch(); }, 300);
    }

    // --- render
    render() {
        const { isLoading, query, faqs, pagination } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">FAQ</Header>
                        <Link to="/admin/faqs/create">
                            <Button primary icon="plus" />
                        </Link>
                    </div>
                    <Input
                        fluid
                        loading={isLoading}
                        placeholder='Search...'
                        onChange={(e) => { this.searchFaqs(e.target.value); }}
                        value={query}
                    />
                    <Table>
                        <Table.Header>
                            <Row>
                                <HeaderCell>Question</HeaderCell>
                                <HeaderCell>Category</HeaderCell>
                                <HeaderCell singleLine textAlign="right">Actions</HeaderCell>
                            </Row>
                        </Table.Header>
                        <Table.Body>
                            {faqs.map((faq) => (
                                <Row key={faq.id}>
                                    <Cell>
                                        <Link to={`/admin/faqs/${faq.id}`}>
                                            {faq.question}
                                        </Link>
                                    </Cell>
                                    <Cell>
                                        {faq.categories.map(c => c.name).join(', ')}
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        <Link to={`/admin/faqs/${faq.id}`}>
                                            <Button
                                                basic
                                                size="mini"
                                                icon="pencil"
                                            />
                                        </Link>
                                        <span className={tableStyles.actionsDivider}> | </span>
                                        <DeleteAction
                                            message="Are you sure you want to delete this FAQ?"
                                            onDelete={() => { this.deleteFaq(faq); }}
                                        />
                                    </Cell>
                                </Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
                <Pagination data={pagination} onClick={this.fetch} />
            </Container>
        );
    }
}

export default AdminFaqsListView;
