import React from 'react';
import { connect } from 'react-redux';
import dispatch from '../../utils/dispatch';
import styles from './styles.module.scss';

class PageLoader extends React.Component
{
    state = {
        animate: false,
        loaded: false,
        show: true,
    };

    /**
     * Receive props
     */
    componentWillReceiveProps = (props) => {
        const { status } = props;

        if (status !== false && status !== this.props.status) {
            if (status === true) {
                this.setState({ show: true, animate: true });
            } else if (status === 'loaded') {
                this.setState({ animate: false, loaded: true });

                setTimeout(() => {
                    this.setState({ loaded: false, show: false });
                    dispatch('loader', 'setStatus', false);
                }, 200);
            }
        }
    }

    // --- render
    render() {
        const { offset } = this.props;
        const { animate, loaded, show } = this.state;

        if (!show) {
            return null;
        }

        return (
            <div className={[
                styles.container,
                !loaded && animate ? styles.animate : null,
                loaded ? styles.loaded : null,
            ].join(' ')} style={{ top: offset ? offset : 0 }} />
        );
    }
}

export default connect(state => ({
    status: state.loader.status,
}))(PageLoader);
