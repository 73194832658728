import React from 'react';
import { Redirect } from 'react-router';
import styles from './styles.module.scss';
import TitleBar from '../../../components/front/TitleBar';

class CategoryView extends React.Component
{
    // --- render
    render() {
        if (window.location.pathname === '/admin') {
            return <Redirect to="/admin/assets" />
        }

        return (
            <div>
                <TitleBar />
                <div className="section--alt">
                    <div className="cont">
                        <div className={styles.noResults}>
                            <h1>404</h1>
                            Page not found
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CategoryView;
