import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


class ProductLink extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            product: false,
        };
    }

    componentDidMount = () => {
        const pid = localStorage.getItem('pid');
        const product = find(this.props.products, { id: parseInt(pid || 1) });
        this.setState({ product });
    }

    // --- render
    render() {
        const { product } = this.state;
        const { to, products, ...rest } = this.props;
        const link = product ? `/${product.slug}${to}` : to;

        return (
            <Link
                to={link}
                {...rest}
            />
        );
    }
}

export default connect((state) => ({
    products: state.product.products,
}))(ProductLink);
