import React from "react";
import { colors } from "../../../utils/constants";
import ProductLink from '../ProductLink';
import styles from "./styles.module.scss";

const Shape = () => (
    <div className={styles.shape}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.45 47.39">
            <g id="Laag_2" data-name="Laag 2">
                <g id="Laag_1-2" style={{ fill: "#fff" }} data-name="Laag 1">
                    <polygon
                        className="cls-1"
                        style={{ fill: "#acacac" }}
                        points="0 0 9.24 0 2.63 9.99 17.66 9.99 17.66 43.77 0 43.77 0 0"
                    />
                    <polygon
                        className="cls-2"
                        style={{ fill: "#959595" }}
                        points="24.27 17.13 36.49 9.99 36.49 15.99 36.49 47.39 24.27 42.67 24.27 17.13"
                    />
                    <rect
                        className="cls-3"
                        style={{ fill: "#ebebeb" }}
                        x="36.49"
                        y="15.99"
                        width="3.96"
                        height="27.78"
                    />
                </g>
            </g>
        </svg>
        <div className={styles.background}></div>
    </div>
);

class TitleBar extends React.Component {
    // --- render
    render() {
        const { colorIndex, label, icon, isSwitch } = this.props;
        let slug = window.location.pathname.split("/")[1];

        if (colorIndex === -1 || !colors[colorIndex]) {
            return null;
        }

        return (
            <div
                className={[
                    styles.container,
                    this.props.small ? styles.small : null,
                    isSwitch ? styles.switch : null
                ].join(" ")}
            >
                <div className={styles.otherHalf} />
                <div className="cont">
                    <div
                        className={styles.inner}
                        style={{ backgroundColor: colors[colorIndex].primary }}
                    >
                        <div className={styles.logo}>
                            <Shape />
                            {/* <img src="/lojuxta.png" alt="" /> */}
                            {!isSwitch ? (
                                <>
                                    {this.props.logo !== false && <ProductLink to="/"><img src={`/logos/${slug}.png`} alt="" /></ProductLink>}
                                    Learning resource
                                </>
                            ) : (
                                <div className={styles.spacer}></div>
                            )}
                        </div>
                        {label ? (
                            <div className={styles.label}>
                                {label}
                                {icon ? (
                                    <img src={`/${icon}-white.png`} alt="" />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default TitleBar;
