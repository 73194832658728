import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import ProductLink from "../ProductLink";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

class MainNavigation extends React.Component {
    state = {
        navOpen: false,
        categories: []
    };

    componentWillReceiveProps = props => {
        if (this.state.categories.length <= 0) {
            let { categories } = props;

            if (window.innerWidth > 992) {
                categories = categories.map(c => {
                    c.name = c.name.replace(" ", "<br>");
                    return c;
                });
            }

            this.setState({
                categories
            });
        }
    };

    /**
     * Toggle nav
     */
    toggleNav = () => {
        if (window.innerWidth <= 992) {
            this.setState({
                navOpen: !this.state.navOpen
            });
        }
    };

    // --- render
    render() {
        const { navOpen, categories } = this.state;
        const { category } = this.props;

        let root = category;
        let isSwitch = window.location.pathname === "/switch";

        if (isSwitch) {
            return null;
        }

        if (category.root) {
            root = category.root;
        }

        return (
            <div className="cont">
                <div className={styles.container}>
                    <a href="/switch" className={styles.logo}>
                        <img
                            src="/logo.svg"
                            alt="Amryt"
                            style={{ height: 40, marginTop: 10 }}
                        />
                    </a>
                    <Icon
                        className={styles.bars}
                        name={navOpen ? "close" : "bars"}
                        onClick={this.toggleNav}
                    />
                    {!isSwitch && <ul
                        className={[
                            styles.navContainer,
                            navOpen && styles.navOpen
                        ].join(" ")}
                    >
                        {categories.map(c => {
                            let url = c.full_url;

                            if (c.children && c.children.length) {
                                url = c.children[0].full_url;

                                if (c.children[0].children.length) {
                                    url = c.children[0].children[0].full_url;
                                }
                            }

                            return (
                                <li
                                    className={[
                                        styles.navItem,
                                        root.id === c.id || category.id === c.id
                                            ? styles.isActive
                                            : null
                                    ].join(" ")}
                                    key={`cat-${c.id}`}
                                    onClick={this.toggleNav}
                                >
                                    <ProductLink
                                        to={`/category/${url}`}
                                        dangerouslySetInnerHTML={{
                                            __html: c.name
                                        }}
                                    />
                                </li>
                            );
                        })}
                    </ul>}
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    category: state.category.current
}))(MainNavigation);
