import React from 'react';

class Pagination extends React.Component
{
    // --- render
    render() {
        const { data, onClick } = this.props;
        const pages = [];

        if (!data || data.last_page <= 1) {
            return null;
        }

        if (data) {
            for (let i = data.current_page - 3; i <= data.current_page + 3; i++) {
                if (i >= 1 && i <= data.last_page) {
                    pages.push(<a
                        className={`${i === data.current_page ? 'active ' : ''} item`}
                        href="/"
                        onClick={(e) => { onClick(i); return e.preventDefault(); }}
                        key={`page-${i}`}
                    >{i}</a>);
                }
            }
        }

        return (
            <div className="ui pagination menu">
                {data.current_page > 1 ? (
                    <a className="item" href="/" onClick={(e) => { onClick(data.current_page - 1); return e.preventDefault(); }}>
                        Previous
                    </a>
                ) : null}

                {data.current_page - 3 >= 2 ? (
                    <a className="disabled item" href="/">
                        ...
                    </a>
                ) : null}

                {pages}

                {data.last_page > 5 && data.current_page <= (data.last_page - 3) ? (
                    <a className="disabled item" href="/">
                        ...
                    </a>
                ) : null}

                {data.current_page < data.last_page ? (
                    <a className="item" href="/" onClick={(e) => { onClick(data.current_page + 1); return e.preventDefault(); }}>
                        Next
                    </a>
                ) : null}
            </div>
        );
    }
}

export default Pagination;
