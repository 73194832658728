import React from 'react';
import { connect } from 'react-redux';
import ProductLink from '../ProductLink';
import { countBy, each, find } from 'lodash';
import moment from 'moment';
import { Icon, Form } from 'semantic-ui-react';
import { colors, locales } from '../../../utils/constants';
import styles from './styles.module.scss';

class Asset extends React.Component
{
    state = {
        availableLocales: [],
        currentFile: false,
        currentLocale: 'en',
        localeFiles: [],
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { data, user } = this.props;
        let { files } = data;

        if (this.props.title === false) {
            files = files.filter((f) => !f.video_url);
        }

        if (files.length > 0) {
            const availableLocales = [];
            const fileLocales = files.map(f => f.locale);
            const localeCount = countBy(fileLocales);

            each(locales, (value) => {
                if (fileLocales.indexOf(value.key) !== -1) {
                    const v = localeCount[value.key] && localeCount[value.key] > 1 ? `${value.text} (${localeCount[value.key]} files)` : value.text;

                    availableLocales.push({
                        key: value.key,
                        text: v,
                        value: value.value,
                    });
                }
            })

            const currentLocale = user && find(availableLocales, {key: user.locale}) ? user.locale : files[0].locale;

            this.setState({
                availableLocales,
                currentFile: find(files, {locale: currentLocale}) || files[0],
                currentLocale,
                localeFiles: files.filter(f => f.locale === currentLocale && f.download_url),
            });
        }
    }

    /**
     * Set current file
     */
    setCurrentFile = (v) => {
        const { files } = this.props.data;
        const file = find(files, { id: v });

        this.setState({
            currentFile: file,
        });
    }

    /**
     * Set locale
     */
    setLocale = (v) => {
        const { files } = this.props.data;
        const localeFiles = files.filter(f => f.locale === v && !f.video_url);
        const file = localeFiles[0];

        this.setState({
            currentLocale: v,
            currentFile: file,
            localeFiles,
        });
    }

    // --- render
    render() {
        const { data, colorIndex } = this.props;
        const { availableLocales, currentFile, currentLocale, localeFiles } = this.state;

        if (colorIndex === -1) {
            return null;
        }

        return (
            <div className={styles.container}>
                {this.props.title !== false ? (
                    <div className={styles.title}>
                        <div className={styles.titleIcon}>
                            <Icon name={data.icon} />
                        </div>
                        <div className={styles.titleInfo}>
                            <span>
                                {data.date ? data.date : ''}
                                {data.author && data.date ? ' | ' : ''}
                                {data.author ? data.author : ''}
                            </span>
                            {data.title}
                        </div>
                    </div>
                ) : null}
                {this.props.content !== false ? (
                    <div className={styles.content}>
                        {data.thumbnail ? (
                            <div className={styles.thumbnail}>
                                <div style={{ backgroundColor: colors[colorIndex].primary }} />
                                <img src={`${data.thumbnail}?${moment(data.updated_at).format('X')}`} alt="" />
                            </div>
                        ) : null}
                        <div className={styles.innerContent}>
                            <div dangerouslySetInnerHTML={{ __html: data.description}} />
                            {currentFile ? (
                                <div className={styles.download}>
                                    {data.type !== 'video' && data.files.length > 1 ? (
                                        <div>
                                            <Form.Select
                                                options={availableLocales}
                                                onChange={(e, { value }) => { this.setLocale(value); }}
                                                value={currentLocale}
                                                style={{ marginRight: 15 }}
                                            />
                                            {localeFiles.length > 1 ? (
                                                <div style={{ marginTop: 5 }}>
                                                    <Form.Select
                                                        options={localeFiles.map(o => ({
                                                            key: o.id,
                                                            text: o.name,
                                                            value: o.id,
                                                        }))}
                                                        onChange={(e, { value }) => { this.setCurrentFile(value); }}
                                                        value={currentFile.id}
                                                        style={{ marginRight: 15 }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    {data.type !== 'video' ? (<>
                                        {data.type !== 'iread' && (
                                            <a
                                                className={styles.downloadBtn}
                                                href={currentFile.download_url}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                Download {this.props.title === false ? 'slide deck' : ''}
                                                <span><Icon name="chevron right" /></span>
                                            </a>
                                        )}
                                        <a
                                            className={styles.downloadBtn}
                                            href={currentFile.embed_url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{ marginLeft: 10 }}
                                        >
                                            View
                                            <span><Icon name="chevron right" /></span>
                                        </a>
                                    </>) : (
                                        <ProductLink
                                            className={styles.downloadBtn}
                                            to={currentFile.download_url}
                                        >
                                            Watch movie
                                            <span><Icon name="chevron right" /></span>
                                        </ProductLink>
                                    )}
                                    {data.type === 'video' && data.quiz && (
                                        <ProductLink
                                            className={styles.downloadBtn}
                                            to={`${currentFile.download_url}?quiz=1`}
                                            style={{ marginLeft: window.innerWidth > 992 ? 10 : 0 }}
                                        >
                                            Take the quiz
                                            <span><Icon name="chevron right" /></span>
                                        </ProductLink>
                                    )}
                                    {data.type === 'video' && data.files.length > 1 && (
                                        <a
                                            className={styles.downloadBtn}
                                            href={data.files[1].download_url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{ marginLeft: window.innerWidth > 992 ? 10 : 0 }}
                                        >
                                            {data.button_label || 'Download slide deck'}
                                            <span><Icon name="chevron right" /></span>
                                        </a>
                                    )}
                                </div>
                            ) : null}
                            {data.link && (
                                <a
                                className={styles.downloadBtn}
                                href={data.link}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Visit website
                                <span><Icon name="chevron right" /></span>
                            </a>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default connect(state => ({
    user: state.user.current,
}))(Asset);
