import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Segment, TextArea, Checkbox } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import history from '../../../../utils/history';
import request from '../../../../utils/request';

const { Button, Group, Input } = Form;

class AdminProductsListView extends React.Component
{
    state = {
        errors: {},
        product: {},
        sections: [],
        logo: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        request('admin/sections').then(({ data }) => {
            this.setState({
                sections: data.data,
            });
        });

        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/products/${this.props.match.params.id}`).then(({ data }) => {
            this.setState({
                product: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, product } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            product: {
                ...product,
                [name]: value,
            },
        });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { product } = this.state;
        if (product.region) {
            product.region = product.region.join(',');
        }

        request[id ? 'patch' : 'post'](`admin/products${id ? `/${id}` : ''}`, product).then(() => {
            toast({
                type: 'success',
                icon: '',
                description: 'Product saved succesfully',
                time: 2500,
            });

            if (this.state.logo) {
                const fd = new FormData();
                fd.append('logo', this.state.logo);
                request.post(`admin/products/${id}/logo`, fd).then(() => {
                    history.push('/admin/products');
                });
            } else {
                history.push('/admin/products');
            }
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
            });
        });
    }

    // --- render
    render() {
        const { errors, product, sections } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">{product.id ? 'Edit' : 'Create'} product</Header>
                        <Link to="/admin/products">Back</Link>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Select
                            error={errors.section_id && errors.section_id !== false}
                            label="Section"
                            name="section_id"
                            options={sections.map((o) => ({
                                key: o.name,
                                value: o.id,
                                text: o.name,
                            }))}
                            onChange={this.handleInput}
                            value={product.section_id}
                            required
                        />
                        <Input
                            error={errors.name && errors.name !== false}
                            label="Name"
                            name="name"
                            onChange={this.handleInput}
                            required
                            value={product.name || ''}
                        />
                        <Form.Select
                            error={errors.region && errors.region !== false}
                            label="Region"
                            name="region"
                            options={[{
                                key: 'reg-both',
                                value: 'useu-med',
                                text: 'US Medical & EMEA Medical',
                            }, {
                                key: 'reg-us-comm',
                                value: 'us-comm',
                                text: 'US Commercial',
                            }, {
                                key: 'reg-eu-comm',
                                value: 'eu-comm',
                                text: 'EMEA Commercial',
                            }]}
                            multiple
                            onChange={this.handleInput}
                            value={product.region || ['useu-med']}
                            required
                        />
                        <Input
                            label="Logo"
                            name="logo"
                            onChange={(e, data) => this.setState({ logo: e.target.files[0]})}
                            type="file"
                        />
                        <Group>
                            <TextArea
                                error={errors.disclaimer && errors.disclaimer !== false}
                                label="Disclaimer"
                                name="disclaimer"
                                onChange={this.handleInput}
                                value={product.disclaimer || ''}
                            />
                        </Group>
                        <Group>
                            <Checkbox
                                label="Send notifications for new assets"
                                onChange={(e, { checked }) => this.handleInput(e, {name:'send_notifications', value: checked})}
                                checked={product.send_notifications}
                            />
                        </Group>
                        <Group>
                            <Button primary>Save</Button>
                            <Link to="/admin/products" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                        </Group>
                    </Form>
                </Segment>
            </Container>
        );
    }
}

export default AdminProductsListView;
