import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Input, Segment, Table } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import request from '../../../../utils/request';
import tableStyles from '../../../../styles/table.module.scss';
import DeleteAction from '../../../../components/admin/DeleteAction';
import Pagination from '../../../../components/admin/Pagination';

const { Cell, HeaderCell, Row} = Table;
let timer = 0;

class AdminSlidesListView extends React.Component
{
    state = {
        isLoading: false,
        query: '',
        pagination: {},
        slides: [],
        confirmDelete: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Delete tag
     */
    deleteSlide = (slide) => {
        request.delete(`admin/slides/${slide.id}`).then(() => {
            this.fetch();

            toast({
                type: 'success',
                icon: '',
                description: 'Slide deleted succesfully',
                time: 2500,
            });
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/slides?q=${this.state.query}`).then(({ data }) => {
            this.setState({
                isLoading: false,
                pagination: data.meta,
                slides: data.data,
            });
        });
    }

    /**
     * Search
     */
    searchSlides = (query) => {
        clearTimeout(timer);

        this.setState({
            isLoading: true,
            query,
        });

        timer = setTimeout(() => { this.fetch(); }, 300);
    }

    // --- render
    render() {
        const { isLoading, pagination, query, slides } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">Slides</Header>
                        <Link to="/admin/slides/create">
                            <Button primary icon="plus" />
                        </Link>
                    </div>
                    <Input
                        fluid
                        loading={isLoading}
                        placeholder='Search...'
                        onChange={(e) => { this.searchSlides(e.target.value); }}
                        value={query}
                    />
                    <Table>
                        <Table.Header>
                            <Row>
                                <HeaderCell>Slide</HeaderCell>
                                <HeaderCell singleLine textAlign="right">Actions</HeaderCell>
                            </Row>
                        </Table.Header>
                        <Table.Body>
                            {slides.map((slide) => (
                                <Row key={slide.id}>
                                    <Cell>
                                        <Link to={`/admin/slides/${slide.id}`}>
                                            {slide.name}
                                        </Link>
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        <Link to={`/admin/slides/${slide.id}`}>
                                            <Button
                                                basic
                                                size="mini"
                                                icon="pencil"
                                            />
                                        </Link>
                                        <span className={tableStyles.actionsDivider}> | </span>
                                        <DeleteAction
                                            message="Are you sure you want to delete this slide?"
                                            onDelete={() => { this.deleteSlide(slide); }}
                                        />
                                    </Cell>
                                </Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
                <Pagination data={pagination} onClick={this.fetch} />
            </Container>
        );
    }
}

export default AdminSlidesListView;
