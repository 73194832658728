import React, { Component } from "react";
import { connect } from "react-redux";
import ProductLink from '../../../components/front/ProductLink';

import request from "../../../utils/request";
import styles from "./styles.module.scss";
import TitleBar from '../../../components/front/TitleBar';

class ListView extends Component {
  constructor() {
    super();

    this.state = {
      categories: []
    };
  }
  componentDidMount() {
    request("categories?all=1").then(({ data }) => {
      this.setState({
        categories: data
      });
    });
  }

  render() {
    let base = "/category/";
    let { categories } = this.state;
    return (
      <div>
        <TitleBar colorIndex={5} />
        <div className={styles.container}>
          <ul>
            {categories.map(cat => (
              <List key={cat.id} base={base} cat={cat} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

class List extends Component {
  render() {
    let { cat, base } = this.props;
    return (
      <li key={`base/${cat.id}`}>
        <ProductLink to={`${base}${cat.full_url}`}>
          <span dangerouslySetInnerHTML={{ __html: cat.name }} />
        </ProductLink>
        {cat.children &&
          cat.children.map(c => (
            <ul key={`children/${c.id}`}>
              <List base={`${base}`} cat={c} />
            </ul>
          ))}
      </li>
    );
  }
}

export default connect(state => ({
  category: state.category
}))(ListView);
