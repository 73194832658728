import store from '../store';
import { actions as categoryActions } from '../reducers/category';
import { actions as loaderActions } from '../reducers/loader';
import { actions as productActions } from '../reducers/product';
import { actions as userActions } from '../reducers/user';

const dispatch = (redux, action, data) => {
    let actions;

    switch (redux) {
        case 'category':
            actions = categoryActions;
            break;
        case 'loader':
            actions = loaderActions;
            break;
        case 'product':
            actions = productActions;
            break;
        case 'user':
            actions = userActions;
            break;
        default:
            actions = userActions;
    }

    if (typeof action === 'string') {
        store.dispatch(actions[action](data));
    } else {
        for (let a in action) {
            store.dispatch(actions[a](action[a]));
        }
    };
}

export default dispatch;
