import React from "react";
import { find } from 'lodash';
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { SemanticToastContainer } from "react-semantic-toasts";
import { getParentCategories } from "./services/category";
import dispatch from "./utils/dispatch";
import history from "./utils/history";
import request from "./utils/request";
import PageLoader from "./components/PageLoader";

import TopBar from "./components/front/TopBar";
import MainNavigation from "./components/front/MainNavigation";
import HomepageView from "./views/front/HomepageView";
import CategoryView from "./views/front/CategoryView";
import NotFoundView from "./views/front/NotFoundView";
import LoginView from "./views/front/LoginView";
import LogoutView from "./views/front/LogoutView";
import Footer from "./components/front/Footer";
import SitemapView from "./views/front/SitemapView";
import SwitchView from "./views/front/SwitchView";

import AdminTopBar from "./components/admin/TopBar";
import AdminLoginView from "./views/admin/LoginView";
import AdminAssetsEditView from "./views/admin/assets/EditView";
import AdminAssetsListView from "./views/admin/assets/ListView";
import AdminAssetsSortView from "./views/admin/assets/SortView";
import AdminCategoriesEditView from "./views/admin/categories/EditView";
import AdminCategoriesListView from "./views/admin/categories/ListView";
import AdminFaqsEditView from "./views/admin/faqs/EditView";
import AdminFaqsListView from "./views/admin/faqs/ListView";
import AdminProductsEditView from "./views/admin/products/EditView";
import AdminProductsListView from "./views/admin/products/ListView";
import AdminSectionsEditView from "./views/admin/sections/EditView";
import AdminSectionsListView from "./views/admin/sections/ListView";
import AdminSlidesEditView from "./views/admin/slides/EditView";
import AdminSlidesListView from "./views/admin/slides/ListView";
import AdminQuizzesEditView from "./views/admin/quizzes/EditView";
import AdminQuizzesListView from "./views/admin/quizzes/ListView";
import AdminTagsEditView from "./views/admin/tags/EditView";
import AdminTagsListView from "./views/admin/tags/ListView";
import AdminUsersEditView from "./views/admin/users/EditView";
import AdminUsersListView from "./views/admin/users/ListView";

require("./icons");

function injectGTM(user) {
    if (user) {
        window.UserType = "LoggedIn";
        window.CountryCode = user.locale;
        window.UserID = user.id;
    }

    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-5TSTLT8");
}

class App extends React.Component {
  state = {
    categories: [],
    products: false,
  };

  /**
   * Mount
   */
  componentDidMount = () => {
    if (window.location.href.indexOf("/admin") !== -1) {
      document.body.style.backgroundColor = "#f7f7f7";
    }

    request("profile").then(({ data }) => {
      injectGTM(data);
      dispatch("user", "setCurrent", data);

      getParentCategories().then(({ data }) => {
        this.setState({
          categories: data
        });
      });

      // products
      request('products?all=1').then(({ data }) => {
        dispatch('product', 'setProducts', data);
        this.setState({ products: data });

        if (!/\/(admin|login|auth|logout|switch)/.test(window.location.pathname)) {
          const pid = localStorage.getItem('pid');

          if (pid) {
            const product = find(data, { id: parseInt(pid) });
            if (product) {
              const reg = new RegExp(`^/${product.slug}`);

              if (product && !reg.test(window.location.pathname)) {
                window.location.href = `/${product.slug}`;
              }
            } else {
              window.location.href = `/switch`;
            }
          } else {
            window.location.href = `/switch`;
          }
        }
      });
    }).catch(() => {
      injectGTM();
    });
  };

  agree = () => {
    request.post('auth/agree').then(() => {
      window.location.reload();
    });
  }

  /**
   * Is admin
   */
  isAdmin = () => {
    return window.location.href.indexOf("/admin") !== -1;
  };

  // --- render
  render() {
    const { user } = this.props;
    const { categories, products } = this.state;

    if (!products && !/\/(login|auth|logout)/.test(window.location.pathname)) {
      return false;
    }

    return (
      <Router history={history}>
        <div className={this.isAdmin() ? "" : "font"}>
          {user ? (
            <div>
              <PageLoader offset={this.isAdmin() ? 40 : 0} />
              {this.isAdmin() ? (
                <AdminTopBar />
              ) : (
                <div>
                  <TopBar />
                  <MainNavigation categories={categories} />
                </div>
              )}
            </div>
          ) : null}
          <Switch>
            <Route path="/" exact component={HomepageView} />
            <Route path="/auth/reset-password" component={LoginView} />

            <Route path="/logout" exact component={LogoutView} />
            <Route path="/login" exact component={LoginView} />
            <Route path="/switch" exact component={SwitchView} />
            <Route path="/:product" exact component={HomepageView} />

            <Route path="/admin/login" exact component={AdminLoginView} />

            <Route
              path="/admin/assets/create"
              exact
              component={AdminAssetsEditView}
            />
            <Route
              path="/admin/assets/sort"
              exact
              component={AdminAssetsSortView}
            />
            <Route
              path="/admin/assets/:id"
              exact
              component={AdminAssetsEditView}
            />
            <Route path="/admin/assets" exact component={AdminAssetsListView} />

            <Route
              path="/admin/categories/create"
              exact
              component={AdminCategoriesEditView}
            />
            <Route
              path="/admin/categories/:id"
              exact
              component={AdminCategoriesEditView}
            />
            <Route
              path="/admin/categories"
              exact
              component={AdminCategoriesListView}
            />

            <Route
              path="/admin/faqs/create"
              exact
              component={AdminFaqsEditView}
            />
            <Route path="/admin/faqs/:id" exact component={AdminFaqsEditView} />
            <Route path="/admin/faqs" exact component={AdminFaqsListView} />

            <Route
              path="/admin/quizzes/create"
              exact
              component={AdminQuizzesEditView}
            />
            <Route
              path="/admin/quizzes/:id"
              exact
              component={AdminQuizzesEditView}
            />
            <Route
              path="/admin/quizzes"
              exact
              component={AdminQuizzesListView}
            />

            <Route
              path="/admin/tags/create"
              exact
              component={AdminTagsEditView}
            />
            <Route path="/admin/tags/:id" exact component={AdminTagsEditView} />
            <Route path="/admin/tags" exact component={AdminTagsListView} />

            <Route
              path="/admin/products/create"
              exact
              component={AdminProductsEditView}
            />
            <Route path="/admin/products/:id" exact component={AdminProductsEditView} />
            <Route path="/admin/products" exact component={AdminProductsListView} />

            <Route
              path="/admin/sections/create"
              exact
              component={AdminSectionsEditView}
            />
            <Route path="/admin/sections/:id" exact component={AdminSectionsEditView} />
            <Route path="/admin/sections" exact component={AdminSectionsListView} />

            <Route
              path="/admin/slides/create"
              exact
              component={AdminSlidesEditView}
            />
            <Route path="/admin/slides/:id" exact component={AdminSlidesEditView} />
            <Route path="/admin/slides" exact component={AdminSlidesListView} />

            <Route
              path="/admin/users/create"
              exact
              component={AdminUsersEditView}
            />
            <Route
              path="/admin/users/:id"
              exact
              component={AdminUsersEditView}
            />
            <Route path="/admin/users" exact component={AdminUsersListView} />

            <Route
              path="/:product/category/:category/:subcategory/:subsub/:asset"
              exact
              component={CategoryView}
            />
            <Route
              path="/:product/category/:category/:subcategory/:subsub"
              exact
              component={CategoryView}
            />
            <Route
              path="/:product/category/:category/:subcategory"
              exact
              component={CategoryView}
            />
            <Route path="/:product/category/:category" exact component={CategoryView} />

            <Route path="/:product/sitemap" exact component={SitemapView} />

            <Route component={NotFoundView} />
          </Switch>
          {user && categories && !this.isAdmin() ? (
            <Footer categories={categories} />
          ) : null}
          <SemanticToastContainer />
          {user && !user.agreed_at && (
            <div className="modal" onClick={this.agree}>
                <div>
                    <p>The content on the Amryt Academy is intended for internal educational purposes and the content must not be used externally without MRC approval for medical materials and PRC approval for promotional materials.</p>
                    <ul>
                        <li>All activities must be organized and conducted in compliance with applicable laws, regulations, codes, Amryt Code of Conduct, Amryt Ethical Principles and Amryt procedural documents.</li>
                        <li>At planning phase and prior to conducting an activity in a country whether it is globally or country initiated, it must be ensured that it fully adheres to and satisfies all applicable country regulations, requirements and Amryt procedural documents.</li>
                        <li>Promotion is only allowed following Marketing Authorization or price and reimbursement depending on the countries regulations.</li>
                        <li>All materials used in activities and interactions must be reviewed and approved in accordance with applicable procedural documents prior to their use.</li>
                    </ul>
                    <div><button>Agree</button></div>
                </div>
            </div>
          )}
        </div>
      </Router>
    );
  }
}

export default connect(state => ({
    user: state.user.current
}))(App);
