import React from "react";
import { Link } from "react-router-dom";
import { Container, Form, Header, Segment } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import { locales } from "../../../../utils/constants";
import history from "../../../../utils/history";
import request from "../../../../utils/request";

const { Button, Checkbox, Group, Input, Select } = Form;

const roles = [
  { key: "admin", text: "Administrator", value: "admin" },
  { key: "user", text: "User", value: "user" }
];

class AdminUsersListView extends React.Component {
  state = {
    errors: {},
    user: { locale: "en", role: "user" },
    products: [],
  };

  /**
   * Mount
   */
  componentDidMount = () => {
    request('admin/products').then(({ data }) => {
      this.setState({
        products: data.data,
      });
    });

    if (this.props.match.params.id) {
      this.fetch();
    }
  };

  /**
   * Fetch
   */
  fetch = () => {
    request(`admin/users/${this.props.match.params.id}`).then(({ data }) => {
      this.setState({
        user: data
      });
    });
  };

  /**
   * Handle input
   */
  handleInput = (e, { name, value }) => {
    const { errors, user } = this.state;

    this.setState({
      errors: {
        ...errors,
        [name]: false
      },
      user: {
        ...user,
        [name]: value
      }
    });
  };

  /**
   * Save
   */
  handleSubmit = () => {
    const { id } = this.props.match.params;
    const { user } = this.state;

    request[id ? "patch" : "post"](`admin/users${id ? `/${id}` : ""}`, user)
      .then(() => {
        toast({
          type: "success",
          icon: "",
          description: "User saved succesfully",
          time: 2500
        });
        history.push("/admin/users");
      })
      .catch(error => {
        toast({
          type: "error",
          icon: "",
          title: "Something went wrong",
          description: "Please check all required fields",
          time: 2500
        });

        this.setState({
          ...this.state,
          errors: error.response.data.errors
        });
      });
  };

  // --- render
  render() {
    const { errors, user, products } = this.state;

    return (
      <Container className="admin-page">
        <Segment padded>
          <div className="admin-page-header">
            <Header as="h1">{user.id ? "Edit" : "Create"} user</Header>
            <Link to="/admin/users">Back</Link>
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Select
              error={errors.role && errors.role !== false}
              label="Role"
              name="role"
              options={roles}
              onChange={this.handleInput}
              required
              value={user.role}
            />
            <Group widths="equal">
              <Input
                error={errors.first_name && errors.first_name !== false}
                label="First name"
                name="first_name"
                onChange={this.handleInput}
                required
                value={user.first_name}
              />
              <Input
                error={errors.last_name && errors.last_name !== false}
                label="Last name"
                name="last_name"
                onChange={this.handleInput}
                required
                value={user.last_name}
              />
            </Group>
            <Input
              error={errors.email_address && errors.email_address !== false}
              label="Email address"
              name="email_address"
              onChange={this.handleInput}
              required
              value={user.email_address}
            />
            <Select
              error={errors.locale && errors.locale !== false}
              label="Default locale"
              name="locale"
              options={locales}
              onChange={this.handleInput}
              required
              value={user.locale}
            />
            <Select
              error={errors.region && errors.region !== false}
              label="Region"
              name="region"
              options={[{
                  key: 'reg-both',
                  value: 'useu-med',
                  text: 'US Medical & EMEA Medical',
              }, {
                  key: 'reg-us-comm',
                  value: 'us-comm',
                  text: 'US Commercial',
              }, {
                  key: 'reg-eu-comm',
                  value: 'eu-comm',
                  text: 'EMEA Commercial',
              }]}
              onChange={this.handleInput}
              value={user.region || 'useu-med'}
              required
            />

            <label style={{ fontWeight: 700, marginBottom: 5, display: 'block' }}>Products</label>
              {products && products.map((product) => (<Checkbox
                label={product.name}
                checked={user.products && user.products.includes(product.id)}
                onChange={(e, v) => {
                  this.setState({
                    user: {
                      ...user,
                      products: v.checked ? [...(user.products || []), product.id] : user.products.filter(o => o !== product.id),
                    }
                  });
                }}
              />))}

            {user.id ? null : (<>
              <label style={{ fontWeight: 700, marginBottom: 5, display: 'block' }}>Send invite</label>
              <Checkbox
                label="Send password via email"
                onChange={(e, v) => {
                  this.setState({
                    user: {
                      ...user,
                      send_invite: v.checked === true ? 1 : 0
                    }
                  });
                }}
              />
            </>)}
            <Group>
              <Button primary>Save</Button>
              <Link to="/admin/users" style={{ marginTop: 9, marginLeft: 10 }}>
                Cancel
              </Link>
            </Group>
          </Form>
        </Segment>
      </Container>
    );
  }
}

export default AdminUsersListView;
