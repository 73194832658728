import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Container,
    Header,
    Input,
    Segment,
    Table,
    Icon
} from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import request from "../../../../utils/request";
import tableStyles from "../../../../styles/table.module.scss";
import DeleteAction from "../../../../components/admin/DeleteAction";
import Pagination from "../../../../components/admin/Pagination";

const { Cell, HeaderCell, Row } = Table;
let timer = 0;

class AdminQuizzesListView extends React.Component {
    state = {
        isLoading: false,
        query: "",
        pagination: {},
        quizzes: [],
        confirmDelete: false
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch(1);
    };

    /**
     * Delete quiz
     */
    deleteQuiz = quiz => {
        request.delete(`admin/quizzes/${quiz.id}`).then(() => {
            this.fetch(1);

            toast({
                type: "success",
                icon: "",
                description: "Quiz deleted succesfully",
                time: 2500
            });
        });
    };

    /**
     * Fetch
     */
    fetch = page => {
        request(`admin/quizzes?q=${this.state.query}&page=${page || 1}`).then(
            ({ data }) => {
                this.setState({
                    isLoading: false,
                    pagination: data.meta,
                    quizzes: data.data
                });
            }
        );
    };

    /**
     * Search
     */
    searchQuizzes = query => {
        clearTimeout(timer);

        this.setState({
            isLoading: true,
            query
        });

        timer = setTimeout(() => {
            this.fetch(1);
        }, 300);
    };

    // --- render
    render() {
        const { isLoading, pagination, query, quizzes } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">Quizzes</Header>
                        <div>
                            <a
                                href={`${request.defaults.baseURL}admin/quizzes/export`}
                                rel="noopener noreferrer"
                                style={{ marginLeft: 5 }}
                                target="_blank"
                            >
                                <Button basic>
                                    <Icon name="file excel outline" /> Export
                                </Button>
                            </a>
                            <Link to="/admin/quizzes/create">
                                <Button primary icon="plus" />
                            </Link>
                        </div>
                    </div>
                    <Input
                        fluid
                        loading={isLoading}
                        placeholder="Search..."
                        onChange={e => {
                            this.searchQuizzes(e.target.value);
                        }}
                        value={query}
                    />
                    <Table>
                        <Table.Header>
                            <Row>
                                <HeaderCell>Quiz</HeaderCell>
                                <HeaderCell singleLine textAlign="right">
                                    # Results
                                </HeaderCell>
                                <HeaderCell singleLine textAlign="right">
                                    Actions
                                </HeaderCell>
                            </Row>
                        </Table.Header>
                        <Table.Body>
                            {quizzes.map(quiz => (
                                <Row key={quiz.id}>
                                    <Cell>
                                        <Link to={`/admin/quizzes/${quiz.id}`}>
                                            {quiz.name}
                                        </Link>
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        {quiz.result_count}
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        <Link to={`/admin/quizzes/${quiz.id}`}>
                                            <Button
                                                basic
                                                size="mini"
                                                icon="pencil"
                                            />
                                        </Link>
                                        <span
                                            className={
                                                tableStyles.actionsDivider
                                            }
                                        >
                                            {" "}
                                            |{" "}
                                        </span>
                                        <DeleteAction
                                            message="Are you sure you want to delete this quiz? All results will be deleted as well."
                                            onDelete={() => {
                                                this.deleteQuiz(quiz);
                                            }}
                                        />
                                    </Cell>
                                </Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
                <Pagination data={pagination} onClick={this.fetch} />
            </Container>
        );
    }
}

export default AdminQuizzesListView;
