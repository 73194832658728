import React from 'react';
import { Link } from 'react-router-dom';
import { remove } from 'lodash';
import { Container, Checkbox, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import Nestable from 'react-nestable';
import history from '../../../../utils/history';
import request from '../../../../utils/request';
import styles from './styles.module.scss';

const { Button, Group, Input } = Form;

class AdminQuizzesListView extends React.Component
{
    state = {
        assets: [],
        errors: {},
        quiz: {questions: [], name: ''},
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        request(`admin/assets?all=1&simple=1`).then(({ data }) => {
            this.setState({
                assets: data.map((a) => ({
                    key: a.id,
                    text: a.title,
                    value: a.id,
                })),
            });
        });

        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/quizzes/${this.props.match.params.id}`).then(({ data }) => {
            this.setState({
                quiz: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, quiz } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            quiz: {
                ...quiz,
                [name]: value,
            },
        });
    }

    /**
     * Handle question input
     */
    handleQuestionInput = (e, { name, value }, question) => {
        const { quiz } = this.state;
        quiz.questions[question][name] = value;

        this.setState({
            quiz,
        });
    }

    /**
     * Handle answer input
     */
    handleAnswerInput = (e, { name, value }, question, answer) => {
        const { quiz } = this.state;
        quiz.questions[question].answers[answer][name] = value;

        if (name === 'is_correct') {
            quiz.questions[question].answers.map((a, i) => {
                if (i !== answer) {
                    a.is_correct = false;
                }

                return a;
            })
        }

        this.setState({
            quiz,
        });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { quiz } = this.state;

        request[id ? 'patch' : 'post'](`admin/quizzes${id ? `/${id}` : ''}`, quiz).then(() => {
            toast({
                type: 'success',
                icon: '',
                description: 'Quiz saved succesfully',
                time: 2500,
            });
            history.push('/admin/quizzes');
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
            });
        });
    }

    /**
     * Add question
     */
    addQuestion = (e) => {
        const { quiz } = this.state;

        quiz.questions.push({
            id: `new-${quiz.questions.length + 1}`,
            assets: [],
            content: '',
            mark: 1,
            answers: [{
                id: `new-1`,
                content: '',
                is_correct: false,
            }],
        });

        this.setState({
            quiz,
        });

        return e.preventDefault();
    }

    /**
     * Remove question
     */
    removeQuestion = (index) => {
        const { questions } = this.state.quiz;
        remove(questions, (f, i) => i === index);

        this.setState({
            quiz: {
                ...this.state.quiz,
                questions,
            },
        });
    }

    /**
     * Add answer
     */
    addAnswer = (e, index) => {
        const { quiz } = this.state;

        quiz.questions[index].answers.push({
            id: `new-${quiz.questions[index].answers.length + 1}`,
            content: '',
            is_correct: false,
        });

        this.setState({
            quiz,
        });

        return e.preventDefault();
    }

    /**
     * Remove answer
     */
    removeAnswer = (index, a) => {
        const { questions } = this.state.quiz;
        remove(questions[index].answers, (f, i) => i === a);

        this.setState({
            quiz: {
                ...this.state.quiz,
                questions,
            },
        });
    }

    /**
     * Render question
     */
    renderQuestion = ({ item, index }) => {
        const { assets } = this.state;

        return (
            <Segment key={`question-${index}`}>
                <div className={styles.questionHeader}>
                    <div>
                        <Input
                            label={`Question ${index === undefined ? '' : (index + 1)}`}
                            name="content"
                            onChange={(e, t) => this.handleQuestionInput(e, t, index)}
                            required
                            value={item.content}
                        />
                    </div>
                    <Button
                        icon="trash alternate outline"
                        onClick={() => { this.removeQuestion(index); }}
                        style={{ marginLeft: 15 }}
                        type="button"
                    />
                </div>
                <Grid style={{ paddingRight: 57, marginTop: 10 }}>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            {item.answers.map((answer, a) => (
                                <Segment style={{ padding: 5 }} key={`answer-${a}`}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1}}>
                                            <Input
                                                name="content"
                                                onChange={(e, t) => this.handleAnswerInput(e, t, index, a)}
                                                required
                                                placeholder="Asnwer"
                                                value={answer.content}
                                            />
                                        </div>
                                        <div style={{ margin: '8px 10px 0 10px' }}>
                                            <Checkbox
                                                checked={answer.is_correct}
                                                onChange={(e, t) => this.handleAnswerInput(e, {
                                                    name: 'is_correct',
                                                    value: t.checked,
                                                }, index, a)}
                                                toggle
                                            />
                                        </div>
                                        <div>
                                            {a === item.answers.length - 1 ? (
                                                <Button basic icon="plus" onClick={(e) => { this.addAnswer(e, index); }} type="button" />
                                            ) : (
                                                <Button basic icon="minus" onClick={(e) => { this.removeAnswer(index, a); }} type="button" />
                                            )}
                                        </div>
                                    </div>
                                </Segment>
                            ))}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Input
                                label="Mark"
                                name="mark"
                                onChange={(e, t) => this.handleQuestionInput(e, t, index)}
                                required
                                type="number"
                                value={item.mark}
                            />
                            <Form.Select
                                label="Relevant assets"
                                name="assets"
                                options={assets}
                                onChange={(e, t) => { this.handleQuestionInput(e, t, index); }}
                                value={item.assets}
                                search
                                multiple selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }

    /**
     * Sort questions
     */
    sortQuestions = (questions) => {
        const { quiz } = this.state;
        quiz.questions = questions;
        this.setState({ quiz });
    }

    // --- render
    render() {
        const { errors, quiz } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Container className="admin-page">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Segment padded>
                                    <div className="admin-page-header">
                                        <Header as="h1">{quiz.id ? 'Edit' : 'Create'} quiz</Header>
                                        <Link to="/admin/quizzes">Back</Link>
                                    </div>
                                    <Input
                                        error={errors.name && errors.name !== false}
                                        label="Name"
                                        name="name"
                                        onChange={this.handleInput}
                                        required
                                        value={quiz.name}
                                    />
                                    <Group>
                                        <Button primary>Save</Button>
                                        <Link to="/admin/quizzes" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                                    </Group>
                                </Segment>

                                <div style={{ textAlign: 'right', padding: '10px 0' }}>
                                    <Button primary onClick={(e) => { this.addQuestion(e); }}>
                                        <Icon name="plus" />
                                        Add question
                                    </Button>
                                </div>

                                <Nestable
                                    items={quiz.questions}
                                    maxDepth={1}
                                    renderItem={this.renderQuestion}
                                    onChange={(questions) => this.sortQuestions(questions)}
                                />
                            </Grid.Column>
                            {quiz.id && (
                                <Grid.Column width={4}>
                                    <Segment padded>
                                        <div className="admin-page-header">
                                            <Header as="h2">Results</Header>
                                        </div>
                                        {/* {quiz.results.map((result) => {
                                            <Segment>
                                                <Header as="h4">{result.user.name}</Header>
                                                {result.score}
                                            </Segment>
                                        })} */}
                                    </Segment>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </Container>
            </Form>
        );
    }
}

export default AdminQuizzesListView;
