import request from '../utils/request';

/**
 * Get asset
 */
export const completeQuiz = (quizId, answers) => {
    return request.post(`quizzes/${quizId}/complete`, {
        answers,
    });
}

/**
 * Get quizzes
 */
export const getQuiz = (quizId) => {
    return request(`quizzes/${quizId}?blind=1`);
}