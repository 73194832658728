import React from 'react';
import { Confirm, Icon } from 'semantic-ui-react';
import styles from '../../../styles/table.module.scss';

class DeleteAction extends React.Component
{
    state = {
        show: false,
    };

    /**
     * Toggle
     */
    toggle = () => {
        this.setState({
            show: !this.state.show,
        });
    }

    // --- render
    render() {
        const { message } = this.props;
        const { show } = this.state;

        return (
            <div style={{ display: 'inline' }}>
                <Icon
                    className={styles.actionDelete}
                    name="trash alternate"
                    onClick={this.toggle}
                />
                <Confirm
                    content={message}
                    open={show}
                    onCancel={this.toggle}
                    onConfirm={() => {
                        this.toggle();
                        if (this.props.onDelete) {
                            this.props.onDelete();
                        }
                    }}
                />
            </div>
        );
    }
}

export default DeleteAction;
