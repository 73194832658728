import React from 'react';
import { connect } from 'react-redux';
import { Icon, Input } from 'semantic-ui-react';
import { findIndex, find } from 'lodash';
import Player from '@vimeo/player';
import ProductLink from '../../../components/front/ProductLink';
import { getAssets, getAsset } from '../../../services/assets';
import { getCategoryByUrl, getParentCategories } from '../../../services/category';
import { colors } from '../../../utils/constants';
import dispatch from '../../../utils/dispatch';
import request from '../../../utils/request';
import styles from './styles.module.scss';
import Asset from '../../../components/front/Asset';
import Breadcrumb from '../../../components/front/Breadcrumb';
import TitleBar from '../../../components/front/TitleBar';
import Quiz from '../../../components/front/Quiz';

function pushToDataLayer(data) {
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
}

class CategoryView extends React.Component
{
    state = {
        categories: [],
        assets: [],
        isFetching: false,
        pagination: {current_page: 1},
        tags: [],
        searchTags: {},
        query: '',
        video: false,
        videoObj: false,
    }

    player = React.createRef();

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.container = null;
        this.searchTimer = 0;
    }

    /**
     * Mount
     */
    componentDidMount() {
        getParentCategories().then(({ data }) => {
            this.setState({
                categories: data,
            });
        });

        request('tags?all=1').then(({ data}) => {
            this.setState({
                tags: data,
            });
        });

        this.fetchCategory(this.getUrl(), this.props.match.params.asset);
        window.addEventListener('scroll', this.handleScroll);
    }

    addTracking = async () => {
        let hasTracked25 = false;
        let hasTracked50 = false;
        let hasTracked75 = false;

        if (this.player.current && window.dataLayer) {
            const player = new Player(this.player.current);
            const title = await player.getVideoTitle();

            player.on('play', () => pushToDataLayer({
                event: 'CallToAction',
                Type: 'Video',
                Action: 'Play',
                Identifier: title,
            }));
            const progressAction = {
                event: 'CallToAction',
                Type: 'Video',
                Action: '',
                Identifier: title,
            };

            player.on('progress', e => {
                if (e.percent >= 0.25 && !hasTracked25) {
                    hasTracked25 = true;
                    pushToDataLayer({ ...progressAction, Action: 'Watched 25PCT' });
                }
                if (e.percent >= 0.50 && !hasTracked50) {
                    hasTracked50 = true;
                    pushToDataLayer({ ...progressAction, Action: 'Watched 50PCT' });
                }
                if (e.percent >= 0.75 && !hasTracked75) {
                    hasTracked75 = true;
                    pushToDataLayer({ ...progressAction, Action: 'Watched 75PCT' });
                }
            });

            player.on('ended', e => {
                pushToDataLayer({ ...progressAction, Action: 'Completed' });
            })
        } else {
            console.warn('Can\'t track video progress...');
        }
    }

    /**
     * Unmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Receive props
     */
    componentWillReceiveProps = (props) => {
        const { params } = props.match;
        const url = Object.keys(params).map(i => params[i]).join('/');

        if (url !== this.getUrl()) {
            this.fetchCategory(url, props.match.params.asset);
        }
    }

    /**
     * Create url
     */
    getUrl = () => {
        const { params } = this.props.match;
        return Object.keys(params).map(i => params[i]).join('/');
    }

    /**
     * Scroll
     */
    handleScroll = () => {
        if (this.state.isFetching || !this.container || this.state.video) {
            return;
        }

        const bottom = this.container.getBoundingClientRect().bottom;

        if (bottom - window.innerHeight < 0) {
            this.setState({ isFetching: true }, () => {
                this.fetchAssets(null, this.state.pagination.current_page + 1);
            });
        }
    }

    /**
     * Fetch assets
     */
    fetchAssets(category, page) {
        const { query, searchTags, video } = this.state;

        this.setState({ isFetching: true }, () => {
            if (video) {
                getAsset(video).then(({ data }) => {
                    this.setState({
                        videoObj: data,
                        isFetching: false,
                        assets: [],
                    }, this.addTracking);
                });
            } else {
                getAssets(category || this.props.category.id , page, {
                    q: query,
                    tags: Object.keys(searchTags).filter(t => searchTags[t] === true),
                }).then(({ data }) => {
                    this.setState({
                        assets: [
                            ...this.state.assets,
                            ...data.data,
                        ],
                        isFetching: data.data.length <= 0,
                        pagination: data.meta,
                    });
                });
            }
        });
    }

    /**
     * Fetch
     */
    fetchCategory = (url, asset) => {
        this.setState({
            assets: [],
            video: false,
            videoObj: false,
            query: '',
            tags: [],
        });

        url = url.split('/');
        url.shift();
        url = url.join('/');

        window.scrollTo(0, 0);

        if (/\/[0-9]+$/.test(url)) {
            asset = true;
        }

        if (asset) {
            url = url.split('/');
            const video = url.pop();
            url = url.join('/');

            this.setState({
                video,
                videoObj: false,
            });
        }

        if (url.indexOf('library') !== -1) {
            request('tags?all=1').then(({ data}) => {
                this.setState({
                    tags: data,
                });
            });
        }

        getCategoryByUrl(url).then(({ data }) => {
            dispatch('category', 'updateCurrent', data);
            this.fetchAssets(data.id, 1);
        });
    }

    /**
     * Select tag
     */
    selectTag(tag) {
        const { searchTags } = this.state;
        searchTags[tag.id] = searchTags[tag.id] === true ? false : true;

        this.setState({
            searchTags,
        });

        this.searchAssets(false);
    }

    /**
     * Search
     */
    searchAssets = (e, t) => {
        if (e) {
            this.setState({
                query: t.value,
            });
        }

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setState({
                assets: [],
            });

            this.fetchAssets(null, 1);
        }, 300);
    }

    // --- render
    render() {
        const { category } = this.props;
        const { assets, categories, tags, query, searchTags, videoObj } = this.state;

        if (!category) {
            return null
        }

        // root
        let { root } = category;

        if (!root && !category.parent) {
            root = category;
        }

        // sub sub sub
        let parent = category;

        if (category.parent && category.parent.id !== root.id) {
            parent = find(root.children, {id: category.parent.id});
        }

        // color
        const colorIndex = findIndex(categories, (c) => c.id === root.id);

        if (colorIndex === -1 || !colors[colorIndex]) {
            return null;
        }

        const subsub = [];

        if (parent.children.length) {
            parent.children.map((s) => {
                subsub.push((
                    <ProductLink
                        key={`subsubcat-${s.id}`}
                        to={`/category/${s.full_url}`}
                        style={s.id === category.id ? { color: colors[colorIndex].primary, textDecoration: 'underline' } : {}}
                    >
                        {s.name.replace('<br />', ' ')}
                    </ProductLink>
                ));

                return s;
            });
        }

        return (
            <div>
                <TitleBar label={Object.keys(category.crumb).map(i => category.crumb[i])[0]} colorIndex={colorIndex} icon={root.id} />
                <Breadcrumb crumb={category.crumb} />
                {root.children.length || parent.description ? (
                    <div className="cont">
                        <div className={styles.subCont}>
                            {parent.description ? (
                                <div className={styles.content}>
                                    <div dangerouslySetInnerHTML={{ __html: parent.description}} />
                                </div>
                            ) : null}
                            {root.children.length ? (
                                <div className={styles.subMenu}>
                                    {root.children.map((c) => {
                                        let url = c.full_url;

                                        if (c.children.length) {
                                            url = c.children[0].full_url;
                                        }

                                        return (
                                            <ProductLink
                                                className={[
                                                    styles.subMenuItem,
                                                    c.id === parent.id ? styles.isActive : null,
                                                ].join(' ')}
                                                key={`subcat-${c.id}`}
                                                to={`/category/${url}`}
                                                style={{ backgroundColor: colors[colorIndex].primary }}
                                            >
                                                {c.icon1_path === '' && c.icon2_path === '' && <img
                                                    src={`/${c.icon}-${c.id === parent.id ? 'color' : 'white'}.png`}
                                                    alt=""
                                                />}
                                                {c.icon1_path !== '' && c.id === parent.id && <img
                                                    src={c.icon1_path}
                                                    alt=""
                                                />}
                                                {c.icon2_path !== '' && c.id !== parent.id && <img
                                                    src={c.icon2_path}
                                                    alt=""
                                                />}

                                                <span dangerouslySetInnerHTML={{ __html: c.name}} />
                                                {c.id === parent.id ? (
                                                    <Icon
                                                        name="chevron down"
                                                        style={{ color: colors[colorIndex].primary }}
                                                    />
                                                ) : null}
                                            </ProductLink>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {root.slug.indexOf('library') !== -1 ? (
                    <div className="cont">
                        <div className={styles.searchContainer}>
                            <Input
                                name="query"
                                onChange={this.searchAssets}
                                required
                                value={query}
                                fluid
                                placeholder="Search title, author, categories"
                                icon="search"
                                iconPosition="left"
                            />
                            <div className={styles.tags}>
                                {tags.map((t) => (
                                    <div
                                        className={[
                                            styles.tag,
                                            searchTags[t.id] === true ? styles.activeTag : null,
                                        ].join(' ')}
                                        key={`tag-${t.id}`}
                                        onClick={() => { this.selectTag(t); }}
                                    >
                                        <div>
                                            {t.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="section--alt">
                    <div className="cont">
                        {subsub.length ? (
                            <div className={styles.subsubCont}>
                                {subsub}
                            </div>
                        ) : null}
                        {videoObj ? (
                            <div className={styles.container}>
                                <ProductLink
                                    className={styles.downloadBtn}
                                    to={`/category/${videoObj.categories[videoObj.categories.length - 1].full_url}`}
                                >
                                    <span><Icon name="chevron left" /></span>
                                    Back
                                </ProductLink>
                                <Asset data={videoObj} content={false} colorIndex={colorIndex} />
                                {window.location.search === '?quiz=1' && videoObj.quiz_id && <Quiz id={videoObj.quiz_id} />}
                                <div className={styles.embed}>
                                    <iframe
                                        className={styles.video}
                                        src={`https://player.vimeo.com/video/${videoObj.files[0].video_url.split('/')[3]}?loop=0`}
                                        ref={this.player}
                                        frameBorder="0"
                                        allowFullScreen
                                        title="Title"
                                    />
                                </div>
                                <Asset
                                    data={{
                                        ...videoObj,
                                        type: 'pdf',
                                        thumbnail: false,
                                    }}
                                    title={false}
                                    colorIndex={colorIndex}
                                />
                            </div>
                        ) : null}
                        {assets.length ? (
                            <div
                                className={styles.assetsContainer}
                                ref={(c) => { this.container = c; }}
                            >
                                {assets.map(asset => <Asset data={asset} key={`asset-${asset.id}`}  colorIndex={colorIndex} />)}
                            </div>
                        ) : null}
                        {assets.length <= 0 && !videoObj ? (
                            <div className={styles.noResults}>
                                No Assets found.
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    category: state.category.current,
}))(CategoryView);
