import { createAction, handleActions } from 'redux-actions';

const defaults = {
    current: false,
};

export const actions = {
    setCurrent: createAction('SET_CURRENT'),
};

export default handleActions({
    /**
     * Set current video
     */
    SET_CURRENT: (state, data) => ({
        ...state,
        current: data.payload
    }),
}, defaults);
