export const recursive = (categoriees) => {
    const cats = [];

    const reccat = (categories, lvl) => {
        categories.forEach((category) => {
            cats.push({
                key: category.id,
                text: ''.padStart((lvl * 2), '--') + ' ' + category.name,
                value: category.id,
            });

            if (category.children) {
                reccat(category.children, lvl + 1);
            }
        });
    };

    reccat(categoriees, 0);
    return cats;
}
