import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Header, Icon, Message, Segment } from 'semantic-ui-react';
import request from '../../../utils/request';
import styles from './styles.module.scss';

class LoginView extends React.Component
{
    state = {
        emailAddress: '',
        error: false,
        errors: {
            emailAddress: false,
            password: false,
        },
        password: '',
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        if (!localStorage.getItem('tokens')) {
            this.emailField.focus();
        }
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors } = this.state;

        this.setState({
            [name]: value,
            errors: {
                ...errors,
                [name]: false,
            },
        });
    }

    /**
     * Handle submit
     */
    handleSubmit = () => {
        const { emailAddress, password } = this.state;

        this.setState({
            errors: {
                emailAddress: emailAddress === '',
                password: password === '',
            },
        });

        if (emailAddress && password) {
            request.post('auth/login', {
                email_address: emailAddress,
                password: password,
            }).then(({ data }) => {
                localStorage.setItem('tokens', JSON.stringify(data));
                window.location = '/admin/assets';
            }).catch(({ error }) => {
                this.setState({
                    error: true,
                });
            });
        }
    }

    // --- render
    render() {
        const { emailAddress, error, errors, password } = this.state;

        if (localStorage.getItem('tokens')) {
            return <Redirect to="/admin/assets" />
        }

        return (
            <div className={styles.container}>
                <div>
                    <Segment>
                        <Header as='h2'>
                            <Header.Content>Login</Header.Content>
                        </Header>
                        {error ? (
                            <Message
                                error
                                content='Incorrect credentials'
                            />
                        ) : null}
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Input
                                error={errors.emailAddress}
                                iconPosition='left'
                                name="emailAddress"
                                onChange={this.handleInput}
                                placeholder='Email address'
                                value={emailAddress}
                            >
                                <Icon name='envelope' />
                                <input ref={(c) => { this.emailField = c; }} />
                            </Form.Input>
                            <Form.Input
                                error={errors.password}
                                iconPosition='left'
                                name="password"
                                onChange={this.handleInput}
                                placeholder='Password'
                                type="password"
                                value={password}
                            >
                                <Icon name='lock' />
                                <input />
                            </Form.Input>
                            <Form.Button primary>Login</Form.Button>
                        </Form>
                    </Segment>
                </div>
            </div>
        );
    }
}

export default LoginView;
