import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import ProductLink from "../ProductLink";
import styles from "./styles.module.scss";

class TopBar extends React.Component {
    state = {};

    // --- render
    render() {
        const { user } = this.props;
        let showSmpc = window.location.pathname !== '/switch';

        return (
            <div className={styles.container}>
                <div className="cont">
                    <div className={styles.innerContainer}>
                        <p className={styles.name}>
                            {window.innerWidth > 992 ? "Welcome, " : ""}
                            {user.name}
                        </p>
                        <Link className={styles.logout} to="/logout">
                            {window.innerWidth >= 992 ? (
                                "Logout"
                            ) : (
                                <Icon name="logout" style={{ margin: 0 }} />
                            )}
                        </Link>
                        {showSmpc && (<ProductLink
                            className={styles.smpc}
                            to="/category/core-resources/hcp-materials/spc"
                        >
                            {window.innerWidth >= 992 ? (
                                "SmPC"
                            ) : (
                                <Icon name="globe" style={{ margin: 0 }} />
                            )}
                        </ProductLink>)}
                        <a className={styles.smpc} href="/switch">
                            <Icon name="th" />
                            Products
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    user: state.user.current
}))(TopBar);
