import request from '../utils/request';

/**
 * Get asset
 */
export const getAsset = (assetId) => {
    return request.get(`assets/${assetId}`);
}

/**
 * Get assets
 */
export const getAssets = (categoryId, page, params) => {
    return request.post(`assets/${categoryId}?page=${page}&files=1`, params);
}
