import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { completeQuiz, getQuiz } from "../../../services/quiz";
import styles from "./styles.module.scss";

class Quiz extends React.Component {
    state = {
        answered: {},
        quiz: false,
        currentQuestion: 0,
        result: 0
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        getQuiz(this.props.id).then(({ data }) => {
            this.setState({
                quiz: data
            });
        });
    };

    /**
     * Answer question
     */
    answerQuestion = (question, answer) => {
        const { answered } = this.state;
        answered[question.id] = answer.id;

        this.setState({
            answered
        });
    };

    /**
     * Next question
     */
    nextQuestion = e => {
        const { answered, currentQuestion, quiz } = this.state;

        if (!answered[quiz.questions[currentQuestion].id]) {
            alert("Please select an answer");
            return e.preventDefault();
        }

        this.setState({
            currentQuestion: currentQuestion + 1
        });

        // complete quiz
        if (currentQuestion >= quiz.questions.length - 1) {
            completeQuiz(quiz.id, answered).then(({ data }) => {
                this.setState({
                    result: data.result
                });
            });
        }

        return e.preventDefault();
    };

    // --- render
    render() {
        const { answered, currentQuestion, quiz, result } = this.state;

        if (!quiz) {
            return null;
        }

        const { questions } = quiz;
        const question = questions[currentQuestion];

        return (
            <div className={styles.container}>
                <div className="cont">
                    <div className={styles.question}>
                        {question
                            ? `Question ${currentQuestion + 1}: ${
                                  question.content
                              }`
                            : "Results"}
                    </div>
                    {question &&
                        question.answers.map((a, i) => (
                            <div
                                className={[
                                    styles.answer,
                                    Object.keys(answered).map(i => answered[i]).indexOf(a.id) !== -1
                                        ? styles.answerSelected
                                        : null
                                ].join(" ")}
                                onClick={() => this.answerQuestion(question, a)}
                                key={`answer-${a.id}`}
                            >
                                <span>{String.fromCharCode(97 + i)}</span>
                                <p>{a.content}</p>
                                <span></span>
                            </div>
                        ))}
                    {!question && (
                        <div className={styles.answer}>
                            <p className={styles.result}>
                                YOU SCORED {result} out of {questions.length}
                            </p>
                        </div>
                    )}
                    {question && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 20
                            }}
                        >
                            <div>
                                {currentQuestion > 0 && (
                                    <a
                                        className={[
                                            styles.nextBtn,
                                            styles.prevBtn
                                        ].join(" ")}
                                        onClick={e => {
                                            this.setState({
                                                currentQuestion:
                                                    currentQuestion - 1
                                            });
                                            return e.preventDefault();
                                        }}
                                    >
                                        <span>
                                            <Icon name="chevron left" />
                                        </span>
                                        Previous question
                                    </a>
                                )}
                            </div>
                            <a
                                className={styles.nextBtn}
                                onClick={this.nextQuestion}
                            >
                                {currentQuestion === questions.length - 1
                                    ? "Complete"
                                    : "Next question"}
                                <span>
                                    <Icon name="chevron right" />
                                </span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    user: state.user.current
}))(Quiz);
