import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Input, Segment, Table } from 'semantic-ui-react'
import { toast } from 'react-semantic-toasts';
import Nestable from 'react-nestable';
import request from '../../../../utils/request';
import tableStyles from '../../../../styles/table.module.scss';
import DeleteAction from '../../../../components/admin/DeleteAction';

const { Cell, HeaderCell, Row} = Table;

let timer = 0;

class AdminCategoriesListView extends React.Component
{
    state = {
        isLoading: false,
        query: '',
        pagination: {},
        categories: [],
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Delete category
     */
    deleteCategory = (category) => {
        request.delete(`admin/categories/${category.id}`).then(() => {
            this.fetch();

            toast({
                type: 'success',
                icon: '',
                description: 'Category deleted succesfully',
                time: 2500,
            });
        });
    }

    /**
     * Fetch
     */
    fetch = (flatten) => {
        request(`admin/categories?q=${this.state.query}&flatten=${flatten === true ? 1 : 0}`).then(({ data }) => {
            this.setState({
                isLoading: false,
                pagination: data.meta,
                categories: data.data,
            });
        });
    }

    /**
     * Show recursive categories
     */
    recursiveItem = ({ item }) => {
        return (
            <Segment style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    <Link to={`/admin/categories/${item.id}`}>
                        {item.name}
                    </Link>
                </div>
                <div>
                    <Link to={`/admin/categories/${item.id}`}>
                        <Button
                            basic
                            size="mini"
                            icon="pencil"
                        />
                    </Link>
                    <span className={tableStyles.actionsDivider}> | </span>
                    <DeleteAction
                        message="Are you sure you want to delete this category? All linked asset will no longer be available."
                        onDelete={() => { this.deleteCategory(item); }}
                    />
                </div>
            </Segment>
        )
    };

    /**
     * Search
     */
    searchCategories = (query) => {
        clearTimeout(timer);

        this.setState({
            isLoading: true,
            query,
        });

        timer = setTimeout(() => { this.fetch(query !== ''); }, 300);
    }

    /**
     * Sort categories
     */
    sortCategories = (categories) => {
        request.post('admin/categories/sort', { categories });
    }

    // --- render
    render() {
        const { isLoading, query, categories } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">Categories</Header>
                        <Link to="/admin/categories/create">
                            <Button primary icon="plus" />
                        </Link>
                    </div>
                    <Input
                        fluid
                        loading={isLoading}
                        placeholder='Search...'
                        onChange={(e) => { this.searchCategories(e.target.value); }}
                        value={query}
                    />
                </Segment>
                {query === '' ? (
                    <Nestable
                        items={categories}
                        renderItem={this.recursiveItem}
                        onChange={this.sortCategories}
                    />
                ) : (
                    <Table>
                        <Table.Header>
                            <Row>
                                <HeaderCell>Category</HeaderCell>
                                <HeaderCell>Parent</HeaderCell>
                                <HeaderCell singleLine textAlign="right">Actions</HeaderCell>
                            </Row>
                        </Table.Header>
                        <Table.Body>
                            {categories.map((category) => (
                                <Row key={category.id}>
                                    <Cell>
                                        <Link to={`/admin/categories/${category.id}`}>
                                            {category.name}
                                        </Link>
                                    </Cell>
                                    <Cell>
                                        {category.parent ? (
                                            <Link to={`/admin/categories/${category.parent_id}`}>
                                                {category.parent.name}
                                            </Link>
                                        ) : '-'}
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        <Link to={`/admin/categories/${category.id}`}>
                                            <Button
                                                basic
                                                size="mini"
                                                icon="pencil"
                                            />
                                        </Link>
                                        <span className={tableStyles.actionsDivider}> | </span>
                                        <DeleteAction
                                            message="Are you sure you want to delete this category? All linked asset will no longer be available."
                                            onDelete={() => { this.deleteCategory(category); }}
                                        />
                                    </Cell>
                                </Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Container>
        );
    }
}

export default AdminCategoriesListView;
