import React from 'react';
import dispatch from '../../../utils/dispatch';
import history from '../../../utils/history';

class LogoutView extends React.Component
{
    /**
     * Contructor
     */
    constructor(props) {
        super(props);
        localStorage.removeItem('tokens');
        dispatch('user', 'setCurrent', false);
        history.push('/login');
    }

    // --- render
    render() {
        return null;
    }
}

export default LogoutView;
