import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Checkbox, Form, Grid, Header, Modal, Progress, Segment, Select } from 'semantic-ui-react';
import { each, remove } from 'lodash';
import TinyMCE from 'react-tinymce';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';
import { recursive } from '../../../../utils/category';
import { locales } from '../../../../utils/constants';
import history from '../../../../utils/history';
import request from '../../../../utils/request';

const { Button, Group, Input } = Form;

class AdminAssetsListView extends React.Component
{
    state = {
        asset: {
            categories: [],
            files: [],
            tags: [],
            date: moment().format('DD-MM-YYYY'),
        },
        categories: [],
        errors: {},
        filesToUpload: [],
        isUploading: false,
        loaded: false,
        newThumbnail: false,
        newThumbnailPreview: false,
        quizzes: [],
        tags: [],
        uploadPercentage: 0,
        uploadLength: 0,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        Promise.all([
            request(`admin/categories?all=1`).then(({ data }) => {
                this.setState({
                    categories: recursive(data),
                });
            }),
            request(`admin/tags?all=1`).then(({ data }) => {
                this.setState({
                    tags: data,
                });
            }),
            request(`admin/quizzes?all=1`).then(({ data }) => {
                this.setState({
                    quizzes: data,
                });
            })
        ]).then(() => {
            this.setState({ loaded: true });
        });

        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Add video
     */
    addVideo = () => {
        const { files } = this.state.asset;

        files.push({
            name: '',
            locale: 'en',
            extension: 'video',
            video_url: '',
        });

        this.setState({
            asset: {
                ...this.state.asset,
                files,
            },
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/assets/${this.props.match.params.id}`).then(({ data }) => {
            data.categories = data.categories.map(c => c.id);
            data.tags = data.tags.map(c => c.id);
            if (data.date) {
                data.date = data.date.split('-').reverse().join('-');
            }

            this.setState({
                asset: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, asset } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            asset: {
                ...asset,
                [name]: value,
            },
        });
    }

    /**
     * Handle file locale
     */
    handleFileLocale = (value, i) => {
        const { files } = this.state.asset;
        files[i].locale = value;
        this.setState({ files });
    }

    /**
     * Handle upload file locale
     */
    handleUploadFileLocale = (value, i) => {
        const { filesToUpload } = this.state;
        filesToUpload[i].locale = value;
        this.setState({ filesToUpload });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { asset } = this.state;

        request.post(`admin/assets${id ? `/${id}` : ''}`, asset).then(({ data }) => {
            this.setState({
                asset: data,
            });

            if (this.state.newThumbnail) {
                const fd = new FormData();
                fd.append('thumbnail', this.state.newThumbnail);
                request.post(`admin/assets/${data.id}/thumbnail`, fd).then(this.success);
            } else {
                this.success();
            }
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
                newThumbnail: false,
                newThumbnailPreview: false,
            });
        });
    }

    /**
     * Set file
     */
    setFiles = (e) => {
        const { filesToUpload } = this.state;

        each(e.target.files, (file) => {
            if (file.size > 0) {
                filesToUpload.push({
                    name: file.name,
                    file: file,
                    locale: 'en',
                });
            }
        });

        this.setState({ filesToUpload });
    }

    /**
     * Handle file name
     */
    handleUploadFile = (e, i) => {
        const { filesToUpload } = this.state;
        filesToUpload[i][e.target.name] = e.target.value;

        this.setState({
            filesToUpload,
        });
    }

    /**
     * Handle video URL
     */
    handleFile = (e, i) => {
        const { files } = this.state.asset;
        files[i][e.target.name] = e.target.value;

        this.setState({
            asset: {
                ...this.state.asset,
                files,
            },
        });
    }

    /**
     * Remove file
     */
    removeFile = (index, confirm) => {
        const { files } = this.state.asset;
        remove(files, (f, i) => i === index);

        this.setState({
            asset: {
                ...this.state.asset,
                files,
            },
        });
    }

    /**
     * Remove uploaded file
     */
    removeUploadFile = (index) => {
        const { filesToUpload } = this.state;
        remove(filesToUpload, (f, i) => i === index);

        this.setState({
            filesToUpload,
        });
    }

    /**
     * Take new screenshot
     */
    createNewThumbnail = (file) => {
        if (window.confirm('Are you sure you want to override the current thumbnail?')) {
            const { id } = this.props.match.params;

            request.post(`admin/assets/${id}/thumbnail/${file.id}`).then(({ data }) => {
                toast({
                    type: 'success',
                    icon: '',
                    description: 'Thumbnail created succesfully',
                    time: 2500,
                });

                data.categories = data.categories.map(c => c.id);
                data.tags = data.tags.map(c => c.id);
                
                if (data.date) {
                    data.date = data.date.split('-').reverse().join('-');
                }

                this.setState({ asset: data });
            });
        }
    }

    /**
     * Succes
     */
    success = (force) => {
        if (force !== true && this.state.filesToUpload.length) {
            this.uploadFiles();
        } else {
            toast({
                type: 'success',
                icon: '',
                description: 'Asset saved succesfully',
                time: 2500,
            });

            history.push('/admin/assets');
        }
    }

    /**
     * Upload files
     */
    uploadFiles = async () => {
        const { asset, filesToUpload } = this.state;

        this.setState({
            isUploading: true,
            uploadPercentage: 0,
            uploadLength: filesToUpload.length,
        });

        for (const i in filesToUpload ) {
            const fd = new FormData();
            fd.append('file', filesToUpload[i].file);
            fd.append('name', filesToUpload[i].name);
            fd.append('locale', filesToUpload[i].locale);

            const { data } = await request.post(`admin/assets/${asset.id}/upload`, fd, {
                onUploadProgress: (e) => {
                    this.setState({
                        uploadPercentage: (e.loaded / e.total) * 100,
                        currentUpload: (parseInt(i) + 1),
                    });
                },
            });

            this.removeUploadFile(i);
            this.setState({ asset: data });

            if (parseInt(i) >= this.state.uploadLength - 1) {
                this.success(true);
            }
        };
    }

    /**
     * File
     */
    fileSegment = (file, i, onlyNew) => {
        if ((onlyNew && file.id) || (!onlyNew && !file.id)) {
            return null;
        }

        return (
            <Segment key={`file-${i}`}>
                <div style={{ display: 'flex', marginBottom: 10, }}>
                    <div style={{ flex: 1, marginRight: 5 }}>
                        <Input
                            fluid
                            name={file.extension === 'video' ? 'video_url' : 'name'}
                            onChange={(e) => { this.handleFile(e, i); }}
                            placeholder={file.extension === 'video' ? 'Vimeo URL' : 'File name'}
                            value={file[file.extension === 'video' ? 'video_url' : 'name']}
                        />
                    </div>
                    <Button type="button" icon="camera" onClick={() => { this.createNewThumbnail(file); }} />
                    <Button type="button" icon="trash alternate outline" onClick={() => { this.removeFile(i, false); }} />
                </div>
                <Select
                    name="locale"
                    options={locales}
                    onChange={(e, { value }) => { this.handleFileLocale(value, i); }}
                    value={file.locale}
                    fluid
                />
            </Segment>
        );
    }

    /**
     * Set a new thumbnail for upload
     */
    setNewThumbnnail = (e) => {
        e.persist();
        const reader = new FileReader();

        reader.onload = (img) => {
            this.setState({
                newThumbnail: e.target.files[0],
                newThumbnailPreview: img.target.result,
            });
        }

        reader.readAsDataURL(e.target.files[0]);
    }

    // --- render
    render() {
        const { asset, categories, errors, filesToUpload, loaded, newThumbnailPreview, quizzes, tags } = this.state;

        if (!loaded) {
            return null;
        }

        const quizOptions = [];
        quizOptions.push({
            key: 0,
            text: 'No quiz',
            value: null,
        });
        quizzes.map(q => {
            quizOptions.push({
                key: q.id,
                text: q.name,
                value: q.id,
            });
            
            return q;
        });

        return (
            <Container className="admin-page">
                <Form onSubmit={this.handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <Segment padded>
                                    <div className="admin-page-header">
                                        <Header as="h1">{asset.id ? 'Edit' : 'Create'} asset</Header>
                                        <Link to="/admin/assets">Back</Link>
                                    </div>
                                    <Form.Select
                                        error={errors.categories && errors.categories !== false}
                                        label="Categories"
                                        name="categories"
                                        options={categories}
                                        onChange={this.handleInput}
                                        value={asset.categories}
                                        search
                                        multiple selection
                                        required
                                    />
                                    <Form.Select
                                        error={errors.tags && errors.tags !== false}
                                        label="Tags"
                                        name="tags"
                                        options={tags.map(t => ({
                                            key: t.id,
                                            text: t.name,
                                            value: t.id,
                                        }))}
                                        onChange={this.handleInput}
                                        value={asset.tags}
                                        search
                                        multiple selection
                                    />
                                    <Input
                                        iconPosition="left"
                                        icon="calendar"
                                        label="Date (Year only)"
                                        name="date"
                                        onChange={this.handleInput}
                                        value={asset.date}
                                    />
                                    <Input
                                        error={errors.title && errors.title !== false}
                                        label="Title"
                                        name="title"
                                        onChange={this.handleInput}
                                        required
                                        value={asset.title}
                                    />
                                    <Input
                                        error={errors.author && errors.author !== false}
                                        label="Author"
                                        name="author"
                                        onChange={this.handleInput}
                                        value={asset.author}
                                    />
                                    <Form.Field>
                                        <label>Description</label>
                                        <TinyMCE
                                            content={asset.description}
                                            config={{
                                                plugins: 'link lists',
                                                menubar: false,
                                                statusbar: false,
                                                toolbar: 'undo redo | bold italic | bullist numlist | alignleft aligncenter alignright | link',
                                                branding: false,
                                            }}
                                            onChange={(e) => this.handleInput(e, {name: 'description', value: e.target.getContent()})}
                                        />
                                    </Form.Field>
                                    <Form.Select
                                        error={errors.quiz && errors.quiz !== false}
                                        label="Quiz"
                                        name="quiz_id"
                                        options={quizOptions}
                                        onChange={this.handleInput}
                                        value={asset.quiz_id}
                                    />
                                    <Group style={{ margin: '17px 0px' }}>
                                        <Checkbox
                                            label="Highlight"
                                            onChange={(e, v) => this.handleInput(e, {name: 'is_highlighted', value: v.checked === true ? 1 : 0})}
                                            checked={asset.is_highlighted === 1}
                                        />
                                    </Group>
                                    <Group>
                                        <Button primary>Save</Button>
                                        <Link to="/admin/assets" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                                    </Group>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Segment padded>
                                    <Input
                                        label="Website"
                                        name="link"
                                        onChange={this.handleInput}
                                        value={asset.link}
                                    />
                                    <Input
                                        label="Extra button label"
                                        name="button_label"
                                        placeholder="Leave empty for default"
                                        onChange={this.handleInput}
                                        value={asset.button_label || ''}
                                    />
                                </Segment>
                                <Segment padded>
                                    <Header size="small">Files</Header>
                                    {asset.files.map((file, i) => this.fileSegment(file, i))}
                                </Segment>
                                <Segment padded>
                                    <Header size="small">Files to be uploaded</Header>
                                    {filesToUpload.map((file, i) => (
                                        <Segment key={`file-${i}`}>
                                            <div style={{ display: 'flex', marginBottom: 10, }}>
                                                <div style={{ flex: 1, marginRight: 5 }}>
                                                    <Input
                                                        name="name"
                                                        onChange={(e) => { this.handleUploadFile(e, i); }}
                                                        placeholder="File name"
                                                        value={file.name}
                                                    />
                                                </div>
                                                <Button type="button" icon="trash alternate outline" onClick={() => { this.removeUploadFile(i); }} />
                                            </div>
                                            <Select
                                                name="locale"
                                                options={locales}
                                                onChange={(e, { value }) => { this.handleUploadFileLocale(value, i); }}
                                                value={file.locale}
                                                fluid
                                            />
                                        </Segment>
                                    ))}
                                    {asset.files.map((file, i) => this.fileSegment(file, i, true))}
                                    <input
                                        ref={(c) => { this.fileInput = c; }}
                                        type="file"
                                        style={{ opacity: 0, visibility: 'hidden', position: 'absolute' }}
                                        multiple
                                        onChange={this.setFiles}
                                    />
                                    <Group>
                                        <Button
                                            basic
                                            type="button"
                                            onClick={() => {
                                                this.fileInput.click();
                                            }}
                                        >
                                            Add file(s)
                                        </Button>
                                        <Button
                                            basic
                                            type="button"
                                            onClick={this.addVideo}
                                        >
                                            Add video
                                        </Button>
                                    </Group>
                                </Segment>
                                <Segment>
                                    <Header size="small">Thumbnail</Header>
                                    {asset.thumbnail || newThumbnailPreview ? (
                                        <img
                                            alt=""
                                            src={newThumbnailPreview || asset.thumbnail}
                                            style={{ marginBottom: 10, width: '100%' }}
                                        />
                                    ) : null}
                                    <input
                                        accept="image/*"
                                        type="file"
                                        onChange={this.setNewThumbnnail}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Modal
                    basic
                    closeOnDimmerClick={false}
                    open={this.state.isUploading}
                    size='small'
                >
                    <Header content="Uploading files" />
                    <Modal.Content>
                        <Progress percent={this.state.uploadPercentage} indicating success>
                            Uploading {this.state.currentUpload} of {this.state.uploadLength}
                        </Progress>
                    </Modal.Content>
                </Modal>
            </Container>
        );
    }
}

export default AdminAssetsListView;
