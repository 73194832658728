import React from 'react';
import { Container, Menu, Dropdown } from 'semantic-ui-react';
import history from '../../../utils/history';
import request from '../../../utils/request';

const { Item } = Menu;

class TopBar extends React.Component
{
    constructor (props) {
        super(props);

        this.state = {
            productOptions: [],
            productId: 1,
        };
    }

    componentDidMount = () => {
        this.fetch();

        const pid = localStorage.getItem('pid');

        if (pid) {
            this.setState({
                productId: parseInt(pid),
            });
        }
    }

    fetch = () => {
        request(`admin/products?all=1`).then(({ data }) => {
            this.setState({
                productOptions: data.map((o) => ({
                    key: `pid-${o.id}`,
                    value: parseInt(o.id),
                    text: o.name,
                })),
            });
        });
    }

    setProduct = (e, { value }) => {
        localStorage.setItem('pid', parseInt(value));
        window.location.reload();
    }

    // --- render
    render() {
        const { push } = history;
        const { productOptions, productId } = this.state;

        return (
            <div>
                <Menu fixed='top' inverted>
                    <Container>
                        <Item header>
                            Amryt Admin
                        </Item>
                        <Dropdown
                            item
                            options={productOptions}
                            value={productId}
                            onChange={this.setProduct}
                        />
                        <Item as="a" onClick={() => { push('/admin/sections'); }}>
                            Sections
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/products'); }}>
                            Products
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/assets'); }}>
                            Assets
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/categories'); }}>
                            Categories
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/faqs'); }}>
                            FAQ
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/tags'); }}>
                            Tags
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/slides'); }}>
                            Slides
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/quizzes'); }}>
                            Quiz
                        </Item>
                        <Item as="a" onClick={() => { push('/admin/users'); }}>
                            Users
                        </Item>
                    </Container>
                </Menu>
            </div>
        );
    }
}

export default TopBar;
