import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Segment, Form } from 'semantic-ui-react'
import Nestable from 'react-nestable';
import request from '../../../../utils/request';
import { recursive } from '../../../../utils/category';

class AdminAssetsSortView extends React.Component
{
    state = {
        categories: [],
        isLoading: false,
        pagination: {},
        assets: [],
        categoryId: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        request(`admin/categories?all=1`).then(({ data }) => {
            this.setState({
                categories: recursive(data),
            });
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/assets/${this.state.categoryId}/cat?all=1&simple=1`).then(({ data }) => {
            this.setState({
                isLoading: false,
                assets: data,
            });
        });
    }

    /**
     * Show recursive categories
     */
    recursiveItem = ({ item }) => {
        return (
            <Segment style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    {item.title}
                </div>
            </Segment>
        )
    };

    /**
     * Sort assets
     */
    sortAssets = (assets) => {
        request.post('admin/assets/sort', {
            categoryId: this.state.categoryId,
            assets,
        });
    }

    // --- render
    render() {
        const { assets, categoryId, categories } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">Sort assets</Header>
                        <Link to="/admin/assets">
                            <Button basic>Back</Button>
                        </Link>
                    </div>
                    <Form.Select
                        name="categoryId"
                        options={categories}
                        onChange={(e, v) => {
                            this.setState({ categoryId: v.value }, () => {
                                this.fetch();
                            });
                        }}
                        placeholder="Select a category to sort"
                        value={categoryId}
                        search
                        style={{ width: '100%' }}
                    />
                </Segment>
                {categoryId ? (
                    <Nestable
                        items={assets}
                        renderItem={this.recursiveItem}
                        onChange={this.sortAssets}
                        maxDepth={0}
                    />
                ) : null}
            </Container>
        );
    }
}

export default AdminAssetsSortView;
