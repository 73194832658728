import axios from "axios";
import dispatch from "./dispatch";

if (window.location.href.indexOf("localhost") !== -1) {
    axios.defaults.baseURL = "https://amryt-api.test/";
} else if (window.location.href.indexOf("staging") !== -1) {
    axios.defaults.baseURL = "https://staging-api.amryt-academy.com/";
} else {
    axios.defaults.baseURL = "https://api.amryt-academy.com/";
}

axios.defaults.headers.common = {
    "X-Client": "web",
    "X-Requested-With": "XMLHttpRequest"
};

axios.interceptors.request.use(config => {
    if (config.url.indexOf("login") === -1 && localStorage.getItem("tokens")) {
        const access = JSON.parse(localStorage.getItem("tokens"));
        config.headers.Authorization = `${access.access_token}:${access.api_key}`;

        const pid = localStorage.getItem("pid");

        if (pid) {
            config.headers["X-Product-ID"] = pid;
        }
    }

    dispatch("loader", "setStatus", true);
    return config;
});

axios.interceptors.response.use(
    response => {
        dispatch("loader", "setStatus", "loaded");
        return response;
    },
    error => {
        if (
            window.location.href.indexOf("login") === -1 &&
            window.location.href.indexOf("auth") === -1 &&
            error.response &&
            error.response.status === 401
        ) {
            localStorage.removeItem("tokens");
            window.location = `/${
                window.location.href.indexOf("admin") === -1 ? "" : "admin/"
            }login`;
        }

        dispatch("loader", "setStatus", "loaded");
        return Promise.reject(error);
    }
);

export default axios;
