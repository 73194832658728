export const locales = [
    { key: 'en', text: 'UK', value: 'en' },
    { key: 'nl', text: 'Netherlands', value: 'nl' },
    { key: 'de', text: 'Germany', value: 'de' },
    { key: 'es', text: 'Spain', value: 'es' },
    { key: 'it', text: 'Italy', value: 'it' },
    { key: 'gr', text: 'Greece', value: 'gr' },
    { key: 'ar', text: 'Saudi & GCC', value: 'ar' },
    { key: 'tr', text: 'Turkey', value: 'tr' },
    { key: 'no', text: 'Norway', value: 'no' },
    { key: 'se', text: 'Sweden', value: 'se' },
    { key: 'at', text: 'Austria', value: 'at' },
    { key: 'il', text: 'Israel', value: 'il' },
    { key: 'fr', text: 'France', value: 'fr' },
    { key: 'da', text: 'Denmark', value: 'da' },
    { key: 'fi', text: 'Finland', value: 'fi' },
    { key: 'pt', text: 'Portugal', value: 'pt' },
    { key: 'np', text: 'Non-promotional', value: 'np' },
];

export const colors = [{
    primary: 'rgb(0, 84, 147)',
}, {
    primary: 'rgb(116, 55, 81)',
}, {
    primary: 'rgb(255, 148, 0)',
}, {
    primary: 'rgb(189, 82, 0)',
}, {
    primary: 'rgb(146, 144, 0)',
}, {
    primary: 'rgb(73, 185, 229)',
}, {
    primary: '#0d0f24',
}, {
    primary: '#acacac',
}];
