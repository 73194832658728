import React from 'react';
import { each } from 'lodash';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Segment, Grid } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { recursive } from '../../../../utils/category';
import history from '../../../../utils/history';
import request from '../../../../utils/request';

const { Button, Group, Input, Select, TextArea } = Form;

class AdminUsersListView extends React.Component
{
    state = {
        errors: {},
        category: {color: '#000000'},
        categories: [],
        icon1: false,
        icon2: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        request(`admin/categories?all=1`).then(({ data }) => {
            this.setState({
                categories: recursive(data),
            });
        });

        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/categories/${this.props.match.params.id}`).then(({ data }) => {
            this.setState({
                category: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, category } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            category: {
                ...category,
                [name]: value,
            },
        });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { category, icon1, icon2 } = this.state;
        let fd = category;

        if (icon1 || icon2) {
            fd = new FormData();

            each(category, (v, k) => {
                if (v && k !== 'icon') {
                    fd.append(k, v);
                }
            });

            if (icon1) fd.append('icon1', icon1);
            if (icon2) fd.append('icon2', icon2);
        }

        request.post(`admin/categories${id ? `/${id}` : ''}`, fd, {
            config: { headers: {'Content-Type': 'multipart/form-data' }},
        }).then(() => {
            toast({
                type: 'success',
                icon: '',
                description: 'Category saved succesfully',
                time: 2500,
            });
            history.push('/admin/categories');
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
            });
        });
    }

    // --- render
    render() {
        const {errors, categories, category } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">{category.id ? 'Edit' : 'Create'} category</Header>
                        <Link to="/admin/categories">Back</Link>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        {categories.length ? (
                            <Select
                                error={errors.parent_id && errors.parent_id !== false}
                                label="Parent"
                                name="parent_id"
                                options={categories}
                                onChange={this.handleInput}
                                value={category.parent_id}
                                search
                            />
                        ) : null}
                        <Grid style={{ marginBottom: 17 }}>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Input
                                        label="Icon Colored"
                                        name="icon1"
                                        onChange={(e, data) => this.setState({ icon1: e.target.files[0]})}
                                        type="file"
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Input
                                        label="Icon White"
                                        name="icon2"
                                        onChange={(e, data) => this.setState({ icon2: e.target.files[0]})}
                                        type="file"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Input
                            error={errors.name && errors.name !== false}
                            label="Name"
                            name="name"
                            onChange={this.handleInput}
                            required
                            value={category.name}
                        />
                        <TextArea
                            error={errors.description && errors.description !== false}
                            label="Short description"
                            name="description"
                            onChange={this.handleInput}
                            value={category.description}
                        />
                        <Group>
                            <Button primary>Save</Button>
                            <Link to="/admin/categories" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                        </Group>
                    </Form>
                </Segment>
            </Container>
        );
    }
}

export default AdminUsersListView;
